import React from 'react';
import { BoxProps, Box, forwardRef } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';

// Use this for all the Chakra sections. Replacement for bootstrap "container" class.
const Container = forwardRef((props: BoxProps, ref) => {
  return (
    <Box
      ref={ref}
      marginX={'auto'}
      width={'100%'}
      maxWidth={{
        base: '100%',
        lg: pxToRem(1160)
      }}
      paddingX={6}
      {...props}
    />
  );
});

export default Container;
