import { useState, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Block from './Block';
import ButtonLink from './Button/ButtonLink';
import VideoIframe from './chakra-ui-components/Video/VideoIframe';
import Image from './chakra-ui-components/Image/Image';
import CallOutPanel from './CallOutPanel';
import Media from './Media';
import ProfilePersonModal from './ProfilePersonModal';
import { Text } from '@nzte/pallet-ui';
import Heading from './chakra-ui-components/Text/Heading';

/**
 * Contentful Interface
 *
 */
export interface RichTextInterface {
  json: Document;
  links?: {
    entries?: {
      block?: any[];
      inline?: any[];
      hyperlink?: any[];
    };
    assets?: any;
  };
}

/**
 * Component
 *
 */
interface Props {
  text: RichTextInterface;
  className?: string;
  options?: any;
}

const RichText = (props: Props) => {
  const { text } = props;
  if (!text || !text.json) return null;

  const className = props.className ? props.className : '';

  const options = props.options ? props.options : RichText.options;

  return (
    <div className={`rich-text ${className}`}>
      {documentToReactComponents(text.json, options(text.links))}
    </div>
  );
};

/**
 * Options
 *
 */
RichText.options = (links) => {
  const Wrapper = ({ type, children }) => (
    <div className={`rich-text__block rich-text__${type}`}>
      <div className="rich-text__container">
        <div className="row">
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );

  return {
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (documentToHtmlString(node) === '') return null;

        return (
          <Wrapper type={node.nodeType}>
            <Text.P textStyle="pallet.body.md.regular" mb="pallet.interior.md">
              {children}
            </Text.P>
          </Wrapper>
        );
      },

      [BLOCKS.HEADING_1]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <Heading type="h1">{children}</Heading>
        </Wrapper>
      ),

      [BLOCKS.HEADING_2]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <Heading type="h2">{children}</Heading>
        </Wrapper>
      ),

      [BLOCKS.HEADING_3]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <Heading type="h3">{children}</Heading>
        </Wrapper>
      ),

      [BLOCKS.HEADING_4]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <Heading type="h4">{children}</Heading>
        </Wrapper>
      ),

      [BLOCKS.HEADING_5]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <Heading type="h5">{children}</Heading>
        </Wrapper>
      ),

      [BLOCKS.HEADING_6]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <Heading type="h6">{children}</Heading>
        </Wrapper>
      ),

      [BLOCKS.OL_LIST]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <ol>{children}</ol>
        </Wrapper>
      ),

      [BLOCKS.UL_LIST]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <ul>{children}</ul>
        </Wrapper>
      ),

      [BLOCKS.QUOTE]: (node, children) => (
        <Wrapper type={node.nodeType}>
          <q>{children}</q>
        </Wrapper>
      ),

      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        let asset;

        try {
          asset = links.assets.hyperlink.find(
            (elem) => node.data.target.sys.id === elem.sys.id
          );
          if (!asset) return null;
        } catch (err) {
          return null;
        }

        return (
          <a
            href={asset.url}
            target="_blank"
            rel="noopener noreferrer"
            download="test"
          >
            {children}
          </a>
        );
      },

      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        let entry;

        try {
          entry = links.entries.hyperlink.find(
            (elem) => node.data.target.sys.id === elem.sys.id
          );
          if (!entry) return null;
        } catch (err) {
          return null;
        }

        let url = null;

        if (entry.__typename === 'Page') {
          url = '/page/' + entry.urlSlug;
        } else if (entry.__typename === 'FormPage') {
          url = '/form/' + entry.urlSlug;
        } else if (entry.__typename === 'BlogEntry') {
          url = '/blog/' + entry.urlSlug;
        } else if (entry.__typename === 'Blog') {
          url = '/blog';
        }

        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },

      [INLINES.EMBEDDED_ENTRY]: (node: Document) => {
        let entry;

        try {
          entry = links.entries.inline.find(
            (elem) => node.data.target.sys.id === elem.sys.id
          );
          if (!entry) return null;
        } catch (err) {
          return null;
        }

        if (entry.__typename === 'ButtonLink') {
          return <ButtonLink {...entry} />;
        } else if (entry.__typename === 'VideoIframe') {
          return <VideoIframe {...entry} />;
        }

        if (
          node.data.target.fields?.videoURL &&
          node.data.target.fields?.videoURL.length > 0
        ) {
          const video = node.data.target;
          const mediaType = 'video';
          const url = video?.fields?.videoURL;
          return (
            <Media
              hideChapters={true}
              type={mediaType}
              url={url}
              chapters={video?.fields?.chapterMarkerLabel?.chapters ?? []}
              overview={video?.fields?.overview}
              transcript={video?.fields?.transcript}
              caption={video?.fields?.caption}
            />
          );
        }

        return <Block id={entry.sys.id} typename={entry.__typename} />;
      },

      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        let entry;

        try {
          entry = links.entries.block.find(
            (elem) => node.data.target.sys.id === elem.sys.id
          );
          if (!entry) return null;
        } catch (err) {
          return null;
        }

        if (entry.__typename === 'VideoIframe') {
          return <VideoIframe {...entry} />;
        } else if (entry.__typename === 'ProfilePerson') {
          return <ProfilePersonModal entry={entry} />;
        } else if (entry.__typename === 'CallOutPanel') {
          return (
            <Wrapper type="call-out-panel">
              <CallOutPanel {...entry} />
            </Wrapper>
          );
        }

        return <Block id={entry.sys.id} typename={entry.__typename} />;
      },

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        let asset;
        const [imageUrl, setImageUrl] = useState<string>(null);

        try {
          asset = links.assets.block.find(
            (elem) => node.data.target.sys.id === elem.sys.id
          );
          if (!asset) return null;
        } catch (err) {
          return null;
        }

        useEffect(() => {
          if (asset.contentType && asset.contentType.startsWith('image')) {
            setImageUrl(`${asset.url}?fm=webp`);
          }
        }, []);

        if (imageUrl) {
          return (
            <Wrapper type="image">
              <Image
                url={`${imageUrl}&w=1460&h=1100`}
                urlZoom={`${imageUrl}&w=2000&h=1800`}
                caption={asset.description}
              />
            </Wrapper>
          );
        }
      }
    }
  };
};

export default RichText;
