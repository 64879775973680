import React from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import ArrowLineRight from '../../../assets/svgs/arrow-line-right.svg';

type TextLinkProps = {
  label: string;
  href: string;
  isExternal: boolean;
  newTab: boolean;
  iconProps?: BoxProps;
} & BoxProps;

const TextLink = ({
  label,
  href,
  isExternal,
  newTab = false,
  iconProps = {},
  ...passThroughProps
}: TextLinkProps) => {
  return (
    <Box
      fontSize={pxToRem(15)}
      fontWeight="bold"
      _hover={{ textDecoration: 'none' }}
      display="flex"
      alignItems="center"
      as="a"
      href={href}
      className="primary-parent"
      color={'black'}
      textDecoration="none"
      rel={isExternal ? 'noopener noreferrer' : undefined}
      target={newTab ? '_blank' : '_self'}
      transition={'background-color 0.3s ease-in, color 0.3s ease-in'}
      {...passThroughProps}
    >
      <Text
        padding={0}
        marginBottom={0}
        fontWeight="inherit"
        fontSize="inherit"
      >
        {label}
      </Text>
      <Box
        display={isExternal ? 'svg' : 'none'}
        as={ArrowLineRight}
        w={pxToRem(10)}
        h={pxToRem(10)}
        marginLeft={2}
        transition={'all 0.3s ease-out'}
        sx={{
          '.primary-parent:hover &': {
            transform: 'rotate(45deg)'
          }
        }}
        {...iconProps}
      />
    </Box>
  );
};

export default TextLink;
