import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { Swipeable } from 'react-swipeable';
import {
  Carousel as RSCarousel,
  CarouselItem as RSCarouselItem
} from 'reactstrap';
import { pxToRem } from '../styles/chakra/theme-utils';
/**
 * Component
 *
 */
interface Props {
  initialIndex?: number;
  items: any[];
  interval?: boolean;
  textIndicator?: boolean;
  swipeable?: boolean;
}

const Carousel = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(
    props.initialIndex ? props.initialIndex : 0
  );
  const [animating, setAnimating] = useState<boolean>(false);

  const { items, interval, textIndicator, swipeable } = props;

  const singleItem = items.length === 1;

  // Move to prev slide
  const prev = () => {
    if (animating || singleItem) return;
    setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1);
  };

  // Move to next slide
  const next = () => {
    if (animating || singleItem) return;
    setActiveIndex(activeIndex === items.length - 1 ? 0 : activeIndex + 1);
  };

  // Generate carousel
  let carousel = (
    <RSCarousel
      activeIndex={activeIndex}
      next={next}
      previous={prev}
      interval={interval ? interval : null}
    >
      {items.map((item, key) => {
        return (
          <RSCarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={key}
          >
            {item}
          </RSCarouselItem>
        );
      })}

      {!singleItem && (
        <>
          <Carousel.Control direction="prev" onClick={prev} />

          <Carousel.Control direction="next" onClick={next} />
        </>
      )}
    </RSCarousel>
  );

  // Wrap carousel on Swipeable component
  if (swipeable && !singleItem) {
    carousel = (
      <Swipeable onSwipedRight={prev} onSwipedLeft={next} trackMouse={true}>
        {carousel}
      </Swipeable>
    );
  }

  // Return complete block
  return (
    <>
      {carousel}
      {textIndicator && (
        <Box fontSize="13px" mt={-4} mb={4}>
          {activeIndex + 1} of {items.length}
        </Box>
      )}
    </>
  );
};

/**
 * Controls
 *
 */

Carousel.Control = (props) => {
  const { direction, onClick } = props;

  if (!['prev', 'next'].includes(direction)) return null;

  return (
    <Box
      opacity="1 !important"
      cursor="pointer"
      w="20%"
      position="absolute"
      bottom="50%"
      left={direction === 'prev' ? 0 : null}
      right={direction === 'next' ? 0 : null}
      maxW={25}
      role="button"
      onClick={onClick}
      transform={
        direction === 'prev'
          ? 'translateY(50%) rotate(180deg)'
          : 'translateY(50%)'
      }
    >
      <Box
        position="relative"
        overflow="hidden"
        h={pxToRem(50)}
        w={pxToRem(50)}
        borderRadius="50%"
        border="1px solid white"
      >
        <Box
          px={5}
          py={pxToRem(14)}
          css={{
            animationDuration: '0.3s',
            animationTimingFunction: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
            '@keyframes carouselArrowAnimation': {
              '0%': {
                opacity: 1
              },

              '50%': {
                opacity: 0,
                transform: 'translateX(100%)'
              },

              '55%': {
                opacity: 0,
                transform: 'translateX(-100%)'
              },
              to: {
                opacity: 1,
                transform: 'translateX(0)'
              }
            },
            '&:hover': {
              animationName: 'carouselArrowAnimation'
            }
          }}
        >
          <svg
            width="11px"
            height="19px"
            viewBox="0 0 11 19"
            version="1.1"
            xmlBase="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <polyline
              stroke="#FFFFFF"
              strokeWidth="2"
              fill="none"
              transform="translate(0, -0.785454546)"
              points="1.66666667 1.63636364 10 9.81818182 1.66666667 18"
            />
          </svg>
        </Box>
      </Box>
    </Box>
  );
};

export default Carousel;
