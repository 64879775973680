import { Modal } from 'reactstrap';
import { ContentfulVideoIframeFragmentFragment as VideoIframeInterface } from '../../data/generated/graphql';
import VideoIframe from '../chakra-ui-components/Video/VideoIframe';

export interface ModalVideoIframeProps {
  show: boolean;
  video: VideoIframeInterface;
  className?: string;
  size?: string;
  onClose?: () => void;
}

/**
 * Component
 *
 */
const ModalVideoIframe = (props: ModalVideoIframeProps) => {
  const { show, video, onClose } = props;

  // Set modal size
  const size = ['sm', 'md', 'lg'].includes(props.size) ? props.size : 'lg';

  return (
    <Modal isOpen={show} onClosed={onClose} size={size}>
      <button className="close" onClick={onClose} />
      {video && <VideoIframe {...video} margin={0} showCaption={false} />}
    </Modal>
  );
};
export default ModalVideoIframe;
