import { AxiosResponse } from 'axios';
import {
  UpdateCommunicationRequest,
  UpdatePersonalisationRequest,
  UpdateProfileRequest,
  UserPreference
} from '../../models/my-account';
import { axiosInstance } from './axios-instance';

export const getUserPreferences = async (): Promise<
  UserPreference | undefined
> => {
  const userPreferenceUrl = '/api/my-account/user-preference';
  const response = await axiosInstance.get(userPreferenceUrl);
  return response?.data;
};

export const updateProfile = async (
  data: UpdateProfileRequest
): Promise<AxiosResponse> => {
  const profileUrl = '/api/my-account/update-profile';
  const response = await axiosInstance.post(profileUrl, data);
  return response;
};

export const updatePersonalisation = async (
  data: UpdatePersonalisationRequest
): Promise<AxiosResponse> => {
  const personalisationUrl = '/api/my-account/update-personalisation';
  const response = await axiosInstance.post(personalisationUrl, data);
  return response;
};

export const updateUserMetadata = async (data: {
  [key: string]: any;
}): Promise<AxiosResponse> => {
  const userMetadataUrl = '/api/my-account/update-user';
  const response = await axiosInstance.post(userMetadataUrl, data);
  return response;
};

export const updateCommunicationSettings = async (
  data: UpdateCommunicationRequest
): Promise<AxiosResponse> => {
  const communicationUpdateUrl = '/api/my-account/update-communication';
  const response = await axiosInstance.post(communicationUpdateUrl, data);
  return response;
};
