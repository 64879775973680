import { marked } from 'marked';
import {
  ContentfulProfilePersonFragmentFragment,
  ProfilePersonFragmentFragmentDoc
} from '../data/generated/graphql';
import { Box } from '@chakra-ui/react';
import { pxToRem } from '../styles/chakra/theme-utils';

/**
 * Components
 *
 */
interface Props extends ContentfulProfilePersonFragmentFragment {
  onClick: Function;
}

const ProfilePerson = (props: Props) => {
  const { sys, name, subHeading, image, onClick } = props;

  const backgroundImage =
    image && image.url
      ? `url(${image.url}?w=300&h=300&fit=fill&fm=webp)`
      : null;

  return (
    <Box mb={4} onClick={() => onClick(sys.id)}>
      <Box
        w="100%"
        maxW={pxToRem(154)}
        border="1px solid black"
        position="relative"
        borderRadius="50%"
        margin="0 auto"
        mb={2}
        cursor="pointer"
        _before={{
          content: "''",
          display: 'block',
          pt: '100%'
        }}
        css={{
          '&:hover .profile-overlay': {
            opacity: '0.6',
            top: '-50%'
          },
          '&:hover .profile-image': {
            transform: 'scale(1.2)'
          },
          '&:hover .profile-arrow': {
            bottom: '50%',
            transform: 'translateY(50%)'
          }
        }}
      >
        <Box
          bg="grey.04"
          position="absolute"
          borderRadius="50%"
          m={pxToRem(10)}
          overflow="hidden"
          top={0}
          bottom={0}
          left={0}
          right={0}
          transform="translateZ(0)"
        >
          {backgroundImage && (
            <Box
              h="100%"
              w="100%"
              borderRadius="100%"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              backgroundSize="contain"
              transition="all 0.3s ease-in"
              m="auto"
              className="profile-image"
              style={{ backgroundImage }}
            />
          )}
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            opacity="0.5"
            h="175%"
            background="linear-gradient(transparent, black)"
            transition="all 0.3s ease-in"
            className="profile-overlay"
          />
          <Box
            position="absolute"
            m="auto"
            h={5}
            w={5}
            backgroundImage="url('/icons/arrow.svg')"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="50%"
            bottom={pxToRem(10)}
            left={0}
            right={0}
            transition="all 0.3s ease-in"
            className="profile-arrow"
          />
        </Box>
      </Box>

      <Box mt={2} textAlign="center" fontSize="13px">
        <Box mb={0} fontSize="15px" fontWeight="bold">
          {name}
        </Box>
        {subHeading && (
          <Box
            __css={{
              p: {
                mb: 1
              },
              '&:last-child': {
                mb: 0
              }
            }}
            dangerouslySetInnerHTML={{
              __html: marked(subHeading, { breaks: true })
            }}
          />
        )}
      </Box>
    </Box>
  );
};

ProfilePerson.fragment = ProfilePersonFragmentFragmentDoc;
export default ProfilePerson;
