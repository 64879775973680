import React from 'react';
import { BoxProps, Box } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import ArrowLineRight from '../../../assets/svgs/arrow-line-right.svg';

type MobileBoxLinkProps = {
  label: string;
  href: string;
  isExternal?: boolean;
  isActive?: boolean;
  newTab?: boolean;
} & BoxProps;

const MobileBoxLink = ({
  label,
  href,
  isExternal = false,
  isActive = false,
  newTab = false,
  ...passThroughProps
}: MobileBoxLinkProps) => {
  return (
    <Box
      paddingLeft={4}
      paddingRight={5}
      paddingY={2}
      _hover={{ backgroundColor: 'neutral.dark', textDecoration: 'none' }}
      fontSize={pxToRem(15)}
      fontWeight={'400'}
      display="flex"
      alignItems="center"
      as={'a'}
      border={0}
      borderBottomRightRadius="16px"
      href={href}
      className={'primary-parent'}
      backgroundColor={isActive ? 'neutral.dark' : 'white'}
      width={'fit-content'}
      flexGrow={0}
      textDecoration={'none'}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      target={newTab ? '_blank' : '_self'}
      {...passThroughProps}
    >
      <div>{label} </div>
      <Box
        display={isExternal ? 'svg' : 'none'}
        as={ArrowLineRight}
        margin="auto 11px 5px"
        w={pxToRem(10)}
        h={pxToRem(10)}
        marginLeft={2}
        sx={{
          '.primary-parent:hover &': {
            transform: 'rotate(45deg)',
            transition: 'all 0.3s ease'
          }
        }}
      />
    </Box>
  );
};

export default MobileBoxLink;
