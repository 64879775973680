import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { GridItem } from '../Grid/Grid';
import { fullWidth } from '../../../helpers/fullWidth';

export type SpacerProps = {
  isFullWidth?: boolean;
  color?: string;
};

const Spacer = ({ isFullWidth, color, ...props }: SpacerProps & BoxProps) => {
  return (
    <GridItem {...props} column={'span 12'}>
      <Box
        borderBottom={'1px'}
        borderColor={color || 'grey.03'}
        height="1px"
        position="relative"
        {...(isFullWidth === true ? fullWidth : null)}
      />
    </GridItem>
  );
};

export default Spacer;
