import { useState } from 'react';

export interface ButtonInterface {
  label: string;
  subtext?: string;
  style?: string;
  variant?: string;
  link?: {
    href: string;
    target?: string;
    rel?: string;
    download?: string;
  };
  defaultStyle?: any;
  activeStyle?: any;
  onClick?: () => void;
}

const VARIANTS = [
  'custom',
  'ui-color',
  'black',
  'white',
  'outline-black',
  'outline-white'
];

const Button = (props: ButtonInterface) => {
  const { label, link, variant, activeStyle, defaultStyle, onClick } = props;
  const [hover, setHover] = useState<boolean>(false);

  const variantClass = VARIANTS.includes(variant)
    ? `btn-${variant}`
    : 'btn-ui-color';

  const innerProps = {
    ...link,
    className: `btn ${variantClass}`,
    style: null,
    onMouseEnter: null,
    onMouseLeave: null,
    onClick: onClick
  };

  if (activeStyle && defaultStyle) {
    innerProps.style = hover ? activeStyle : defaultStyle;
    innerProps.onMouseEnter = () => setHover(true);
    innerProps.onMouseLeave = () => setHover(false);
  }

  if (link) return <a {...innerProps}>{label}</a>;

  return <button {...innerProps}>{label}</button>;
};

export default Button;
