import { Box, BoxProps } from '@chakra-ui/react';
import ImageZoom from 'react-medium-image-zoom';
import { Image as CImage } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';

/**
 * Component
 *
 */
export interface ImageProps {
  url: string;
  urlZoom?: string;
  title?: string;
  caption?: string;
}

const Image = (imageProps: ImageProps & BoxProps) => {
  const { url, urlZoom, title, caption, objectFit, ...props } = imageProps;

  return (
    <Box mb={5} {...props}>
      {urlZoom && (
        <ImageZoom
          image={{
            src: url,
            alt: title,
            className: 'image__img'
          }}
          zoomImage={{
            src: urlZoom,
            alt: title
          }}
        />
      )}
      {!urlZoom && (
        <CImage
          src={url}
          alt={title}
          width="100%"
          height="100%"
          objectFit={objectFit}
        />
      )}

      {caption && (
        <Box color="grey.01" mt={pxToRem(6.4)} fontSize={pxToRem(12.8)}>
          {caption}
        </Box>
      )}
    </Box>
  );
};

export default Image;
