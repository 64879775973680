import { Box, Text } from '@chakra-ui/react';
import { ConditionalWrapper } from '../../helpers/ConditionalWrapper';
import Spacer from '../chakra-ui-components/Spacer/Spacer';
import { Chapter } from './Chapter';

export const ChapterColumn = ({
  screenSize,
  chapters,
  currentChapter,
  currentChapterHeight,
  mediaRef,
  isPlaying,
  setPlaying
}) => (
  <Box
    gridColumn={['span 12', 'span 12', 'span 12', 'span 4']}
    height={['auto', 'auto', 'auto', currentChapterHeight]}
    display="flex"
    flexDir="column"
  >
    <Text mb={6} pl={4} fontWeight="bold">
      Jump to chapter
    </Text>
    <ConditionalWrapper
      condition={screenSize !== 'base'}
      wrapper={(children) => <Box overflowY="auto">{children}</Box>}
    >
      <Box>
        {chapters.map(({ label, marker }, i) => {
          const isCurrent = currentChapter === i;

          const onClickChapter = () => {
            if (mediaRef.current) {
              if (isCurrent && isPlaying) {
                setPlaying(false);
                return;
              }
              mediaRef.current.seekTo(marker);
              setPlaying(true);
            }
          };
          return (
            <Box key={label} _hover={{ bg: 'grey.04', cursor: 'pointer' }}>
              <Chapter
                isPlaying={isPlaying}
                isCurrent={isCurrent}
                onClick={onClickChapter}
                label={label}
                marker={marker}
              />
            </Box>
          );
        })}
      </Box>
    </ConditionalWrapper>
    <Spacer m={0} display={['auto', 'auto', 'auto', 'none']} isFullWidth />
  </Box>
);
