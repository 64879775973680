import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Text, useOutsideClick } from '@chakra-ui/react';
import { PageNavigationData } from '../../data/navigation.data';
import NavigationLoginButton from './NavigationLoginButton/NavigationLoginButton';
import NavigationPopOver from './navigation-items/NavigationPopOver';
import { pxToRem } from '../../styles/chakra/theme-utils';
import SearchIcon from '../../assets/svgs/search-icon.svg';
import CloseIcon from '../../assets/svgs/close.svg';
import { useDisclosure } from '@chakra-ui/hooks';
import SiteSearchEmbedded from '../SiteSearchEmbedded';
import { isNotEmptyArray } from '../../lib/utils/assertions';
import { NavigationProps } from './model';
import TextLink from './navigation-items/TextLink';
import {
  getHrefFromPageNavigationData,
  isPageNavigationExternalLink,
  shouldOpenPageNavInNewTab
} from '../../lib/utils/link';
import ParentNavigationItem from './navigation-items/ParentNavigationItem';
import ChildWrapper from './navigation-items/ChildWrapper';
import Container from '../chakra-ui-components/Container/Container';
import NzteLogo from './navigation-items/NzteLogo';
import { MegaNavEvent, useNavTracking } from '../../helpers/useGaTracking';

/**
 * @doc
 * - "navigation" is the navigation module. It contains main nav, secondary nav and login button nav.
 * - "parent" is the CURRENT/ACTIVE main navigation item.
 * - "children" is the CURRENT/ACTIVE sub navigation item.
 * - "grandChildren" is the CURRENT/ACTIVE 3rd level sub navigation item.
 */
const NavigationDesktop = ({
  navigation,
  header,
  headerLink,
  isUserLoggedIn,
  userPrefData,
  isHomePage,
  isSiteSearchEnabled,
  headerLogo
}: NavigationProps) => {
  const { main, logIn, secondary } = navigation;

  const navigationRef = useRef<HTMLDivElement>();
  const navigationHeightRef = useRef<HTMLDivElement>();
  const siteSearchRef = useRef();

  // All states for opening and closing.
  const [expandedParent, setExpandedParent] = useState<PageNavigationData>(
    null
  );
  const [children, setChildren] = useState<Array<PageNavigationData>>();
  const [navigationStyle, setNavigationStyle] = useState<string | null>(null);

  const [expandedChild, setExpandedChild] = useState<PageNavigationData>();

  const [navHeight, setNavHeight] = useState(null);

  const {
    onToggle: toggleSearch,
    isOpen: isSearchOpen,
    onClose: closeSearch
  } = useDisclosure();

  useOutsideClick({
    ref: navigationRef,
    handler: () => {
      resetNavigation();
      closeSearch();
    }
  });

  useOutsideClick({
    ref: siteSearchRef,
    handler: () => {
      closeSearch();
    }
  });

  // Navigation height can change with Contentful text, so calculating here.
  const calculateNavHeight = () => {
    if (navigationHeightRef) {
      const height = navigationHeightRef.current.clientHeight;
      setNavHeight(height);
    }
  };

  useEffect(() => {
    calculateNavHeight();
    window?.addEventListener('resize', calculateNavHeight);
    return () => {
      window?.removeEventListener('resize', calculateNavHeight);
    };
  }, []);

  const resetNavigation = () => {
    setChildren(null);
    setExpandedParent(null);
    setExpandedChild(null);
  };

  const handleParentClick = (item: PageNavigationData) => {
    const currentItem = main.find((x) => x.id === item.id);

    if (currentItem) {
      if (expandedParent?.id === currentItem.id) {
        setExpandedChild(null);
        setChildren(null);
        setExpandedParent(null);
        useNavTracking({
          event: MegaNavEvent.CloseMenu,
          navMenuName: item.label,
          navMenuIndex: item.key
        });
        return;
      }

      // If an active parent is clicked then open all the way down until the active grandchild is found
      if (currentItem.active && isNotEmptyArray(currentItem.children)) {
        const activeChild = currentItem.children.find((x) => x.active);
        setExpandedChild(activeChild);
      }

      setNavigationStyle(currentItem?.navigationStyle);
      setChildren(currentItem?.children);
      setExpandedParent(currentItem);
      if (!item?.hrefFromPageNavigationData) {
        useNavTracking({
          event: MegaNavEvent.OpenMenu,
          navMenuName: item.label,
          navMenuIndex: item.key
        });
      } else {
        useNavTracking({
          event: MegaNavEvent.LinkClick,
          navMenuName: item.label,
          navMenuIndex: item.key,
          navMenuLinkText: item.label
        });
      }
    }
  };

  const handleChildrenClick = (item: PageNavigationData) => {
    if (isNotEmptyArray(item.children)) {
      setExpandedChild(expandedChild?.id === item.id ? null : item);
      if (expandedChild?.id === item.id) {
        useNavTracking({
          event: MegaNavEvent.CloseMenu,
          navMenuName: item.label,
          navMenuIndex: item.key
        });
      } else {
        useNavTracking({
          event: MegaNavEvent.OpenMenu,
          navMenuName: item.label,
          navMenuIndex: item.key
        });
      }
    } else {
      useNavTracking({
        event: MegaNavEvent.LinkClick,
        navMenuName: item.label,
        navMenuIndex: item.key,
        navMenuLinkText: item.label
      });
    }
  };

  const handleSearchClick = () => {
    resetNavigation();
    toggleSearch();
  };

  const isNavigationEmpty = !navigation || !isNotEmptyArray(navigation.main);

  return (
    <Box>
      <Box
        height={{
          base: 0,
          md: `${navHeight}px` || (!header ? pxToRem(119) : pxToRem(165)),
          lg: `${navHeight}px` || (!header ? pxToRem(119) : pxToRem(130))
        }}
      />

      {/*Try not to add z-index as that would mess up chakra portal component. */}
      <Box
        ref={navigationRef}
        position={'fixed'}
        top={0}
        w={'full'}
        zIndex={2}
        display={{ base: 'none', md: 'block' }}
      >
        <Box color={'white'} bgColor={'black'} ref={navigationHeightRef}>
          {navigation && (
            <Box w={'full'} position={'relative'}>
              <Flex flexDirection={'column'}>
                <Flex direction={'row'} justifyContent={'space-between'} px={6}>
                  <NzteLogo
                    headerLogo={headerLogo}
                    headerLink={headerLink}
                    mr={{ lg: 10 }}
                    pt={2}
                  />
                  {secondary && (
                    <Flex alignItems={'center'} justifyContent={'flex-end'}>
                      {isNotEmptyArray(secondary) &&
                        secondary.map((link, index) => (
                          <Box key={index} marginRight={7}>
                            {link.children.length > 0 ? (
                              <Box position={'relative'}>
                                <NavigationPopOver
                                  navItem={{ ...link, key: index }}
                                  padding={0}
                                />
                              </Box>
                            ) : (
                              <TextLink
                                label={link.label}
                                href={getHrefFromPageNavigationData(link)}
                                isExternal={isPageNavigationExternalLink(link)}
                                newTab={shouldOpenPageNavInNewTab(link)}
                                _hover={{
                                  color: 'neutral.dark',
                                  textDecoration: 'none'
                                }}
                                color={link.active ? 'neutral.dark' : 'white'}
                                onClick={() => {
                                  useNavTracking({
                                    event: MegaNavEvent.LinkClick,
                                    navMenuName: link.label,
                                    navMenuIndex: index,
                                    navMenuLinkText: link.label
                                  });
                                }}
                              />
                            )}
                          </Box>
                        ))}
                      {isNotEmptyArray(logIn) && (
                        <NavigationLoginButton
                          loginSubMenu={logIn}
                          mobile={false}
                          isUserLoggedIn={isUserLoggedIn}
                          userPrefData={userPrefData}
                        />
                      )}
                    </Flex>
                  )}
                </Flex>

                {header && headerLink && (
                  <TextLink
                    href={headerLink}
                    display={{ base: 'block', lg: 'none' }}
                    paddingX={6}
                    label={header}
                    isExternal={false}
                    newTab={false}
                    fontSize={{ base: pxToRem(22), lg: pxToRem(26) }}
                    fontWeight={'bold'}
                    _hover={{
                      color: 'neutral.dark',
                      textDecoration: 'none'
                    }}
                    color={'white'}
                    marginBottom={4}
                  />
                )}

                {header && !headerLink && (
                  <Box display={{ base: 'block', lg: 'none' }} paddingX={6}>
                    <Text
                      fontSize={{ base: pxToRem(22), lg: pxToRem(26) }}
                      fontWeight={'bold'}
                      padding={0}
                      marginBottom={4}
                    >
                      {header}
                    </Text>
                  </Box>
                )}
              </Flex>

              <Box bgGradient="linear-gradient(360deg, #4D4D4D -27.78%, #000000 100%)">
                <Box
                  bgImage={`url('/images/wave-pattern.svg')`}
                  bgPosition="center"
                  bgRepeat="repeat"
                  backgroundSize="100%"
                  paddingTop={2}
                >
                  <Container>
                    <Flex
                      gridGap={4}
                      display={'flex'}
                      w={'full'}
                      justifyContent={
                        isHomePage ? 'flex-start' : 'space-between'
                      }
                      alignItems={'flex-end'}
                    >
                      <Flex alignItems={'flex-end'}>
                        {header && headerLink && (
                          <TextLink
                            label={header}
                            href={headerLink}
                            isExternal={false}
                            newTab={false}
                            fontSize={{ base: pxToRem(22), lg: pxToRem(26) }}
                            fontWeight={'bold'}
                            padding={0}
                            marginBottom={'1.4rem'}
                            display={{ base: 'none', lg: 'block' }}
                            marginRight={7}
                            whiteSpace={'nowrap'}
                            width={'fit-content'}
                            _hover={{
                              color: 'neutral.dark',
                              textDecoration: 'none'
                            }}
                            color={'white'}
                          />
                        )}
                        {header && !headerLink && (
                          <Text
                            fontSize={{ base: pxToRem(22), lg: pxToRem(26) }}
                            fontWeight={'bold'}
                            padding={0}
                            marginBottom={'1.4rem'}
                            display={{ base: 'none', lg: 'block' }}
                            marginRight={7}
                            whiteSpace={'nowrap'}
                            width={'fit-content'}
                          >
                            {header}
                          </Text>
                        )}

                        {main?.map((link, index) => {
                          if (link?.hidenAfterLogin && isUserLoggedIn) {
                            return (
                              <React.Fragment key={index}></React.Fragment>
                            );
                          } else {
                            return (
                              <ParentNavigationItem
                                onClick={handleParentClick}
                                item={{ ...link, key: index }}
                                isExpanded={expandedParent?.id === link.id}
                                isActive={
                                  link.active && !isNotEmptyArray(link.children)
                                }
                                key={index}
                                marginRight={7}
                              />
                            );
                          }
                        })}
                      </Flex>

                      <Box
                        as={'button'}
                        padding={0}
                        width={pxToRem(23)}
                        height={pxToRem(23)}
                        marginBottom={6}
                        onClick={handleSearchClick}
                        display={
                          !isNavigationEmpty &&
                          !isHomePage &&
                          isSiteSearchEnabled
                            ? 'block'
                            : 'none'
                        }
                      >
                        <Box
                          color={'white'}
                          as={isSearchOpen ? CloseIcon : SearchIcon}
                          width={'100%'}
                          height={'100%'}
                          _hover={{ color: 'neutral.light' }}
                        />
                      </Box>
                    </Flex>
                  </Container>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {/* SECOND LEVEL NAVIGATION */}
        {children && children.length > 0 && (
          <Box
            bg={'white'}
            display={'flex'}
            boxShadow="md"
            px={{ base: 5, lg: 0 }}
          >
            <Container>
              <Flex
                gridColumnGap={8}
                width={'full'}
                flexWrap="wrap"
                justifyContent="flex-start"
                alignItems="flex-start"
                flex={1}
                border="none"
                borderTop="solid"
                borderTopWidth="1px"
                borderTopColor="neutral.light"
              >
                {children.map((link, key) => {
                  if (link?.hidenAfterLogin && isUserLoggedIn) {
                    return <></>;
                  }
                  return (
                    <ChildWrapper
                      onClick={() => handleChildrenClick({ ...link, key })}
                      isExpanded={expandedChild?.id === link.id}
                      navItem={{ ...link, key }}
                      key={`${link.id}`}
                      isUserLoggedIn={isUserLoggedIn}
                      navigationStyle={navigationStyle}
                    />
                  );
                })}
              </Flex>
            </Container>
          </Box>
        )}

        <SiteSearchEmbedded
          visibility={isSearchOpen ? 'visible' : 'hidden'}
          height={isSearchOpen ? 'auto' : '0'}
          opacity={isSearchOpen ? 1 : 0}
          ref={siteSearchRef}
          transition={'opacity 0.5s ease'}
          shadow="md"
          isMainNavigation={true}
        />
      </Box>
    </Box>
  );
};

export default NavigationDesktop;
