// Site-search component that can be embedded inside other blocks.
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Box, BoxProps, Flex, forwardRef } from '@chakra-ui/react';
import SearchIcon from '../assets/svgs/search-icon.svg';
import CloseIcon from '../assets/svgs/close.svg';
import Button from './chakra-ui-components/Button/Button';
import Downshift from 'downshift';
import { fetchSuggestions } from '../services/client/site-search';
import reactStringReplace from 'react-string-replace';
import { pxToRem } from '../styles/chakra/theme-utils';
import { sleep } from '../helpers/time';
import { asyncDebounce, asyncThrottle } from '../helpers/async-debounce';
import Container from './chakra-ui-components/Container/Container';

type SiteSearchEmbedProps = {
  isMainNavigation?: boolean;
  searchInputHeight?: BoxProps['height'];
  defaultQuery?: string;
  encloserPassProps?: BoxProps;
} & BoxProps;

const SiteSearchEmbedded = forwardRef((props: SiteSearchEmbedProps, ref) => {
  const {
    isMainNavigation = false,
    searchInputHeight = pxToRem(69),
    defaultQuery = '',
    encloserPassProps = {},
    ...passThroughProps
  } = props;
  const inputEl = useRef<HTMLInputElement>(null);
  const [suggestions, setSuggestions] = useState([]);

  // delays invoking func until after wait milliseconds have elapsed since the last time the debounced function was invoked
  // https://css-tricks.com/debouncing-throttling-explained-examples/
  const {
    func: debouncedFetchSuggestions,
    debounceInstance: debounceFetchSuggestionsInstance
  } = useMemo(
    () =>
      asyncDebounce<(term: string) => Promise<Array<string> | undefined>>(
        fetchSuggestions,
        400
      ),
    []
  );

  // executed once every 500ms.
  const {
    func: throttledFetchSuggestions,
    throttleInstance: throttleFetchSuggestionsInstance
  } = useMemo(
    () =>
      asyncThrottle<(term: string) => Promise<Array<string> | undefined>>(
        fetchSuggestions,
        500
      ),
    []
  );

  // Checks the order of the api response.
  const waitingResponseFor = useRef(null);

  useEffect(() => {
    return () => {
      debounceFetchSuggestionsInstance.cancel();
      throttleFetchSuggestionsInstance.cancel();
    };
  }, [debouncedFetchSuggestions, throttledFetchSuggestions]);

  const submit = (val) => {
    // Using href instead of next router so that  site-search results page works.
    window.location.href = `${window.location.origin}/site-search?q=${val}`;
  };

  // Input field for the <Downshift /> component
  const downshiftOnChange = (selectedSuggestion: string) => {
    setSuggestions([]);
    submit(selectedSuggestion);
  };

  const getSearchSuggestions = async (term: string) => {
    if (!term || term.length < 2) {
      return;
    }

    // If the input is less than 4 then call the api every 500ms if not call only when the user stops typing using debounce.
    // This is so that the users get quick feed back at the start.
    const fetchPromise =
      term.length < 4 ? throttledFetchSuggestions : debouncedFetchSuggestions;

    // sometimes different api takes different time to resolve. set state only if this is the latest search.
    waitingResponseFor.current = term;

    fetchPromise(term)?.then((result) => {
      if (result && term === waitingResponseFor.current) {
        setSuggestions(result);
      }
    });
  };

  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return;
    getSearchSuggestions(event.target.value);
  };

  const clearSuggestions = () => {
    throttleFetchSuggestionsInstance?.cancel();
    throttleFetchSuggestionsInstance?.cancel();
    setSuggestions([]);
  };

  const Encloser = isMainNavigation ? Container : Box;
  const encloserProps: BoxProps = isMainNavigation
    ? encloserPassProps
    : {
        paddingX: 5,
        ...encloserPassProps
      };

  return (
    <Downshift
      id="site-search-embedded-downshift"
      onChange={downshiftOnChange}
      itemToString={(item) => (item ? item : '')}
      initialInputValue={`${defaultQuery ? defaultQuery : ''}`}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        reset,
        isOpen
      }) => {
        const showSuggestions = isOpen && suggestions && suggestions.length > 0;

        return (
          <Box
            position="relative"
            minWidth="100px"
            backgroundColor="white"
            ref={ref}
            {...passThroughProps}
          >
            <Box {...getRootProps(undefined, { suppressRefError: true })}>
              <Encloser {...encloserProps}>
                <Box
                  position="relative"
                  height={searchInputHeight}
                  width={['100%', '100%']}
                >
                  <Box
                    as={SearchIcon}
                    position="absolute"
                    top={pxToRem(22.5)}
                    width={6}
                    height={6}
                    color="black"
                    zIndex={10}
                    left={0}
                  />
                  <Box
                    {...getInputProps({
                      onKeyDown: async (
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        if (
                          event.key === 'Enter' &&
                          inputEl &&
                          inputEl.current
                        ) {
                          const before = inputValue;
                          await sleep(300);
                          if (
                            inputEl &&
                            inputEl.current &&
                            before === inputEl.current.value
                          ) {
                            submit(inputEl.current.value);
                          }
                        }
                      },
                      onChange: inputOnChange
                    })}
                    placeholder="Search nzte.govt.nz"
                    position="absolute"
                    top={0}
                    left={0}
                    paddingY={6}
                    paddingLeft={13}
                    paddingRight={[5, pxToRem(74)]}
                    backgroundColor="white"
                    borderRadius={0}
                    height={searchInputHeight}
                    borderWidth={0}
                    width="100%"
                    transition="border-width 0.25s ease-out 100ms"
                    ref={inputEl}
                    as="input"
                    sx={{
                      '&:focus': {
                        outline: 'none'
                      }
                    }}
                    _placeholder={{
                      color: 'grey.09',
                      fontSize: ['md', 'xl'],
                      lineHeight: ['22px', '26px'],
                      letterSpacing: [null, '-0.2px']
                    }}
                    color="black"
                    fontSize={['md', 'xl']}
                    lineHeight={['22px', '26px']}
                    letterSpacing={[null, '-0.2px']}
                  />
                  <Flex
                    alignItems="center"
                    position="absolute"
                    right={0}
                    top={0}
                    height={searchInputHeight}
                    display={inputValue ? 'flex' : 'none'}
                  >
                    <Box
                      as="button"
                      border="none"
                      width={4}
                      height={4}
                      onClick={() => {
                        reset();
                        clearSuggestions();
                      }}
                    >
                      <Box as={CloseIcon} />
                    </Box>
                    <Button
                      variant="outlined"
                      brandColor="black"
                      display={['none', 'none', 'block']}
                      marginLeft={2}
                      onClick={() => {
                        submit(inputValue);
                      }}
                      label={'Search'}
                    />
                  </Flex>
                </Box>
              </Encloser>

              <Box position="relative">
                <Box
                  {...getMenuProps({})}
                  position="absolute"
                  backgroundColor="white"
                  zIndex={99}
                  top={0}
                  left={0}
                  paddingX={5}
                  width={showSuggestions ? '100%' : 0}
                  height={showSuggestions ? 'auto' : 0}
                  opacity={showSuggestions ? 1 : 0}
                  transition="opacity 0.35s linear"
                  filter="drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))"
                >
                  <Encloser {...encloserProps}>
                    <Box
                      paddingBottom={2}
                      paddingTop={6}
                      borderTopWidth="1px"
                      borderTopColor="black"
                      flexDirection="column"
                      display="flex"
                      width={'100%'}
                    >
                      {suggestions
                        .filter(
                          (item) =>
                            !inputValue ||
                            item
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                        )
                        .slice(0, 10)
                        .map((item, index) => {
                          return (
                            <Box
                              {...getItemProps({
                                key: `${item}${index}`,
                                index,
                                item
                              })}
                              marginBottom={6}
                              fontSize="0.938rem"
                              width="fit-content"
                              className="suggestion-item-parent"
                              backgroundColor={
                                highlightedIndex === index ? '#D2CFB2' : ''
                              }
                              paddingRight={
                                highlightedIndex === index ? '8px' : 0
                              }
                              borderBottomRightRadius={
                                highlightedIndex === index ? '8px' : 0
                              }
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#D2CFB2',
                                  paddingRight: 2,
                                  borderBottomRightRadius: '8px'
                                }
                              }}
                              transition="background-color 0.3s linear, padding 0.3s linear"
                              paddingLeft={2}
                              paddingy="2px"
                              cursor="pointer"
                            >
                              {reactStringReplace(
                                item,
                                inputValue,
                                (match, i) => (
                                  <Box
                                    as="span"
                                    key={i}
                                    backgroundColor={
                                      highlightedIndex === index
                                        ? 'inherit'
                                        : '#EDECE0'
                                    }
                                    color="black"
                                    fontWeight="bold"
                                    sx={{
                                      '.suggestion-item-parent:hover &': {
                                        backgroundColor: 'inherit'
                                      }
                                    }}
                                  >
                                    {match}
                                  </Box>
                                )
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                  </Encloser>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </Downshift>
  );
});

export default SiteSearchEmbedded;
