import { marked } from 'marked';
import { ContentfulCallOutPanelFragmentFragment } from '../data/generated/graphql';
import ButtonLink from './Button/ButtonLink';
import Icon from './Icon';

/**
 * Component
 *
 **/
interface Props extends ContentfulCallOutPanelFragmentFragment {}

const CallOutPanel = (props: Props) => {
  const { heading, body, buttonsCollection } = props;

  return (
    <div className="call-out-panel">
      <div className="call-out-panel__heading h4">
        <Icon type="info" variant="black" hover={false} />
        {heading}
      </div>
      {body && (
        <div
          className="call-out-panel__body"
          dangerouslySetInnerHTML={{ __html: marked.parse(body) }}
        />
      )}
      {buttonsCollection && (
        <div className="call-out-panel__buttons">
          {buttonsCollection.items.map((btn, key) => (
            <ButtonLink {...btn} key={key} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CallOutPanel;
