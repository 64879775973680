import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from './Icon';

/**
 * Component
 *
 */
interface Props {
  url: string;
  title?: string;
}

const Share = (props: Props) => {
  const { title } = props;
  const url = props.url.startsWith('http')
    ? props.url
    : window.location.origin + props.url;

  return (
    <div className="share">
      <Share.Linkedin url={url} />
      <Share.Email url={url} title={title} />
      <Share.CopyLink url={url} />
      <Share.Facebook url={url} />
      <Share.Twitter url={url} title={title} />
    </div>
  );
};

Share.Facebook = ({ url }) => (
  <Icon
    type="facebook"
    variant="btn-outline-black"
    className="share__icon"
    link={{
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      target: '_blank'
    }}
  />
);

Share.Twitter = ({ url, title }) => (
  <Icon
    type="twitter"
    variant="btn-outline-black"
    className="share__icon"
    link={{
      href: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
      target: '_blank'
    }}
  />
);

Share.Linkedin = ({ url }) => (
  <Icon
    type="linkedin"
    variant="btn-outline-black"
    className="share__icon"
    onClick={() => {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
        'sharein',
        'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'
      );
      return false;
    }}
  />
);

Share.Email = ({ url, title }) => (
  <Icon
    type="email"
    variant="btn-outline-black"
    className="share__icon"
    link={{
      href: `mailto:?subject=${title}&body=${url}`
    }}
  />
);

Share.CopyLink = ({ url }) => {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 2000);
  });

  return (
    <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
      <span className="share__icon share__copy">
        {copied ? (
          <>
            <Icon type="tick" variant="btn-outline-black" />
            <span className="share__copied-text">Link copied</span>
          </>
        ) : (
          <Icon type="link" variant="btn-outline-black" />
        )}
      </span>
    </CopyToClipboard>
  );
};

export default Share;
