import React from 'react';
import Chevron from '../Chevron/Chevron';
import { pxToRem } from '../../../../styles/chakra/theme-utils';
import { BoxProps } from '@chakra-ui/react';

const AnimatedChevronDown = (props: BoxProps & { isOpen: boolean }) => {
  const { isOpen, ...passThroughProps } = props;
  return (
    <Chevron
      direction={'down'}
      width={pxToRem(10)}
      height={pxToRem(6)}
      transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
      transition={'all 0.3s ease'}
      {...passThroughProps}
    />
  );
};

export default AnimatedChevronDown;
