import React, { useEffect, useRef, useState } from 'react';
import { PageNavigationData } from '../../../data/navigation.data';
import { BoxProps, Box, Flex } from '@chakra-ui/react';
import ChildNavigationItem from './ChildNavigationItem';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import { isNotEmptyArray } from '../../../lib/utils/assertions';
import GrandChildNavigationItem from './GrandChildNavigationItem';
import {
  getHrefFromPageNavigationData,
  shouldOpenPageNavInNewTab
} from '../../../lib/utils/link';
import Container from '../../chakra-ui-components/Container/Container';
import { MegaNavEvent, useNavTracking } from '../../../helpers/useGaTracking';

type ChildWrapperProps = {
  onClick: (navItem: PageNavigationData) => void;
  isExpanded: boolean;
  navItem: PageNavigationData;
  isUserLoggedIn?: boolean;
  navigationStyle?: null | string;
} & Omit<BoxProps, 'onClick'>;

const ChildWrapper = ({
  onClick,
  isExpanded,
  navItem,
  isUserLoggedIn,
  navigationStyle,
  ...passThroughProps
}: ChildWrapperProps) => {
  const navigationChildRef = useRef();
  const navigationGrandChildrenRef = useRef();
  const [grandChildrenPos, setGrandChildrenPos] = useState(0);
  const [childMarginBottom, setChildMarginBottom] = useState(0);

  const [forceRerender, setForceRerender] = useState(false);

  const setChildAndGrandChildPos = () => {
    const refNav = navigationChildRef.current || null;
    const refNavGrandChildren = navigationGrandChildrenRef.current || null;
    const grandChildPos = refNav ? refNav.getBoundingClientRect().bottom : 0;
    const childHeight = refNavGrandChildren
      ? refNavGrandChildren.getBoundingClientRect().height
      : 0;
    setChildMarginBottom(childHeight);
    setGrandChildrenPos(grandChildPos);
  };

  useEffect(() => {
    if (isExpanded && isNotEmptyArray(navItem.children)) {
      setChildAndGrandChildPos();
      setForceRerender(true);
    } else {
      setChildMarginBottom(0);
      setGrandChildrenPos(0);
    }
  }, [isExpanded, navItem.children]);

  // A hack to fix the issue where if existing grandchildren were present,
  // the getBoundingClientRect().bottom would take that height in to consideration as well.
  useEffect(() => {
    if (forceRerender) {
      setForceRerender(false);
      setChildAndGrandChildPos();
    }
  }, [forceRerender]);

  return (
    <>
      <ChildNavigationItem
        onClick={onClick}
        item={navItem}
        isExpanded={isExpanded}
        ref={navigationChildRef}
        marginTop={4}
        marginBottom={pxToRem(childMarginBottom) || 0}
        width={'auto'}
        fontWeight={
          navigationStyle === 'Pallet' && !navItem?.navigationImportant
            ? 'normal'
            : 'bold'
        }
        isActive={navItem.active && !isNotEmptyArray(navItem.children)}
        {...passThroughProps}
      />
      {isExpanded && isNotEmptyArray(navItem.children) && (
        <Box
          ref={navigationGrandChildrenRef}
          position="absolute"
          left={0}
          right={0}
          top={pxToRem(grandChildrenPos) || 0}
          bg={'neutral.light'}
          paddingBottom={4}
        >
          <Container>
            <Flex
              gridColumnGap={8}
              justifyContent={'flex-start'}
              alignItems={'center'}
              wrap={'wrap'}
              px={{ base: 5, lg: 0 }}
            >
              {navItem.children.map((grandChild) => {
                if (grandChild?.hidenAfterLogin && isUserLoggedIn) {
                  return <></>;
                } else {
                  return (
                    <GrandChildNavigationItem
                      href={getHrefFromPageNavigationData(grandChild)}
                      label={grandChild.label}
                      isActive={grandChild.active}
                      marginTop={4}
                      newTab={shouldOpenPageNavInNewTab(grandChild)}
                      key={grandChild.id}
                      onClick={() => {
                        useNavTracking({
                          event: MegaNavEvent.LinkClick,
                          navMenuName: navItem.label,
                          navMenuIndex: navItem.key,
                          navMenuLinkText: grandChild.label
                        });
                      }}
                    />
                  );
                }
              })}
            </Flex>
          </Container>
        </Box>
      )}
    </>
  );
};

export default ChildWrapper;
