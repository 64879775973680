import React from 'react';
import { PageNavigationData } from '../../../data/navigation.data';
import { BoxProps, Box } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import Chevron from '../../chakra-ui-components/Icon/Chevron/Chevron';

type MobileNextDrawerItemProps = {
  onClick: (item: PageNavigationData) => void;
  label: string;
  navItem: PageNavigationData;
} & Omit<BoxProps, 'onClick'>;

const MobileNextDrawerItem = ({
  onClick,
  label,
  navItem,
  ...passThroughProps
}: MobileNextDrawerItemProps) => {
  return (
    <Box
      backgroundColor={'white'}
      paddingX={4}
      paddingY={2}
      _hover={{ backgroundColor: 'neutral.dark' }}
      fontSize={pxToRem(15)}
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      as={'button'}
      border={0}
      borderBottomRightRadius="16px"
      onClick={() => onClick(navItem)}
      {...passThroughProps}
    >
      <div>{label} </div>
      <Chevron
        direction={'right'}
        marginLeft={0}
        width={pxToRem(10)}
        height={pxToRem(9)}
      />
    </Box>
  );
};

export default MobileNextDrawerItem;
