import React from 'react';
import { PageNavigationData } from '../../../data/navigation.data';
import { Box, BoxProps } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import Chevron from '../../chakra-ui-components/Icon/Chevron/Chevron';

type MobileSecondaryNexDrawerItemProps = {
  onClick: (item: PageNavigationData) => void;
  label: string;
  navItem: PageNavigationData;
} & Omit<BoxProps, 'onClick'>;

const MobileSecondaryNextDrawerItem = ({
  onClick,
  label,
  navItem,
  ...passThroughProps
}: MobileSecondaryNexDrawerItemProps) => {
  return (
    <Box
      _hover={{ color: 'neutral.dark' }}
      fontSize={pxToRem(15)}
      fontWeight={'bold'}
      display="flex"
      alignItems="center"
      as={'button'}
      border={0}
      padding={0}
      onClick={() => onClick(navItem)}
      color={'white'}
      {...passThroughProps}
    >
      <div>{label} </div>
      <Chevron
        direction={'right'}
        marginLeft={4}
        width={pxToRem(10)}
        height={pxToRem(8)}
      />
    </Box>
  );
};

export default MobileSecondaryNextDrawerItem;
