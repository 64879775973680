import React from 'react';
import { Box, DrawerProps, Text, VStack } from '@chakra-ui/react';
import { PageNavigationData } from '../../../data/navigation.data';
import { isNotEmptyArray } from '../../../lib/utils/assertions';
import MobileNextDrawerItem from '../mobile-navigation-items/MobileNextDrawerItem';
import MobileBoxLink from '../mobile-navigation-items/MobileBoxLink';
import {
  getHrefFromPageNavigationData,
  isPageNavigationExternalLink,
  shouldOpenPageNavInNewTab
} from '../../../lib/utils/link';
import MobileSecondaryNextDrawerItem from '../mobile-navigation-items/MobileSecondaryNextDrawerItem';
import MobileTextLink from '../mobile-navigation-items/MobileTextLink';
import TextLink from '../navigation-items/TextLink';

type NavigationMainDrawerProps = {
  mainItems: Array<PageNavigationData>;
  secondaryItems: Array<PageNavigationData>;
  isOpen: boolean;
  onClose?: () => void;
  logIn?: PageNavigationData[];
  handleMainItemClick?: (navItem: PageNavigationData) => void;
  handleSecondaryClick?: (navItem: PageNavigationData) => void;
  header: string;
  headerLink?: string;
  isUserLoggedIn?: boolean;
} & Omit<DrawerProps, 'children'>;

const MobileMainNavigationDrawer = (props: NavigationMainDrawerProps) => {
  const {
    mainItems,
    secondaryItems,
    handleMainItemClick,
    handleSecondaryClick,
    header,
    headerLink,
    isUserLoggedIn
  } = props;

  return (
    <Box h={'full'} bgGradient="linear(to-b, black, grey.13)">
      <VStack
        w={'full'}
        h={'full'}
        bgImage={`url('/images/wave-pattern.svg')`}
        bgPosition="center"
        backgroundSize="cover"
        bgRepeat="no-repeat"
      >
        <Box padding={6} width="100%" height="100%" overflowY={'auto'}>
          {headerLink ? (
            <TextLink
              label={header}
              href={headerLink}
              isExternal={false}
              newTab={false}
              fontWeight="700"
              fontSize="1.5rem"
              lineHeight="1.875rem"
              letterSpacing={'-0.2px'}
              color="white"
              _focus={{
                color: 'white'
              }}
            />
          ) : (
            <Text
              fontWeight="700"
              fontSize="1.5rem"
              lineHeight="1.875rem"
              letterSpacing={'-0.2px'}
              color="white"
            >
              {header}
            </Text>
          )}

          <Box
            height={0}
            borderBottomWidth={isNotEmptyArray(mainItems) ? '1px' : 0}
            borderColor={'white'}
            marginTop={4}
            marginBottom={6}
          />

          {mainItems?.map((link, index) => {
            if (link?.hidenAfterLogin && isUserLoggedIn) {
              return <></>;
            } else {
              return isNotEmptyArray(link.children) ? (
                <MobileNextDrawerItem
                  onClick={handleMainItemClick}
                  label={link.label}
                  navItem={link}
                  key={index}
                  marginBottom={4}
                />
              ) : (
                <MobileBoxLink
                  label={link.label}
                  href={getHrefFromPageNavigationData(link)}
                  key={index}
                  marginBottom={4}
                  isExternal={isPageNavigationExternalLink(link)}
                  newTab={shouldOpenPageNavInNewTab(link)}
                  onClick={() => handleMainItemClick(link)}
                />
              );
            }
          })}

          <Box
            height={0}
            borderBottomWidth={isNotEmptyArray(secondaryItems) ? '1px' : 0}
            borderColor={'white'}
            marginTop={8}
            marginBottom={12}
          />

          {isNotEmptyArray(secondaryItems) && (
            <Box>
              {secondaryItems.map((link, index) => {
                if (link?.hidenAfterLogin && isUserLoggedIn) {
                  return <></>;
                } else {
                  return isNotEmptyArray(link.children) ? (
                    <MobileSecondaryNextDrawerItem
                      onClick={handleSecondaryClick}
                      label={link.label}
                      navItem={link}
                      marginBottom={6}
                      key={index}
                    />
                  ) : (
                    <MobileTextLink
                      label={link.label}
                      href={getHrefFromPageNavigationData(link)}
                      marginBottom={6}
                      key={index}
                      isExternal={isPageNavigationExternalLink(link)}
                      isActive={link.active}
                      newTab={link.openInNewTab}
                      onClick={() => handleSecondaryClick(link)}
                    />
                  );
                }
              })}
            </Box>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default MobileMainNavigationDrawer;
