import React from 'react';
import { PageNavigationData } from '../../../data/navigation.data';
import { BoxProps, Box, Flex, Text } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import {
  getHrefFromPageNavigationData,
  shouldOpenPageNavInNewTab
} from '../../../lib/utils/link';
import { isNotEmptyArray } from '../../../lib/utils/assertions';
import AnimatedChevronDown from '../../chakra-ui-components/Icon/AnimatedChevronDown/AnimatedChevronDown';

type ParentNavigationItemProps = {
  onClick: (item: PageNavigationData) => void;
  item: PageNavigationData;
  isExpanded: boolean;
  isActive: boolean;
} & Omit<BoxProps, 'onClick'>;

const ParentNavigationItem = ({
  onClick,
  item,
  isExpanded,
  isActive,
  ...passThroughProps
}: ParentNavigationItemProps) => {
  const hrefFromPageNavigationData = getHrefFromPageNavigationData(item);

  const newTab = shouldOpenPageNavInNewTab(item);

  return (
    <Box
      as={hrefFromPageNavigationData ? 'a' : 'button'}
      paddingX={0}
      paddingTop={0}
      border={0}
      width={'fit-content'}
      _hover={{ color: 'neutral.dark', textDecoration: 'none' }}
      color={isExpanded || isActive ? 'neutral.dark' : 'white'}
      onClick={() => onClick({ ...item, hrefFromPageNavigationData })}
      href={hrefFromPageNavigationData || undefined}
      target={hrefFromPageNavigationData ? (newTab ? '_blank' : '_self') : ''}
      _after={{
        display: 'block',
        content: '""',
        borderBottom: 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: 'orange.70',
        transition: 'transform 0.2s ease-in',
        transform: isExpanded || isActive ? 'scaleX(1)' : 'scaleX(0)'
      }}
      sx={{
        '&:hover:after': {
          transformOrigin: '0% 50%',
          transform: 'scaleX(1)'
        }
      }}
      textOverflow={'ellipsis'}
      minW={'auto'}
      {...passThroughProps}
    >
      <Flex alignItems={'center'} paddingBottom={6}>
        <Text
          padding={0}
          marginBottom={0}
          fontWeight="bold"
          fontSize={pxToRem(15)}
          lineHeight={pxToRem(21)}
        >
          {item.label}
        </Text>
        {!hrefFromPageNavigationData && isNotEmptyArray(item.children) && (
          <AnimatedChevronDown
            isOpen={isExpanded}
            marginLeft={3}
            width={2}
            height={pxToRem(5)}
          />
        )}
      </Flex>
    </Box>
  );
};

export default ParentNavigationItem;
