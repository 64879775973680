import React from 'react';
import { BoxProps, Box } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';

type GrandChildNavigationItemProps = {
  href: string;
  label: string;
  isActive: boolean;
  newTab?: boolean;
} & BoxProps;

const GrandChildNavigationItem = ({
  href,
  label,
  isActive,
  newTab = false,
  ...passThroughProps
}: GrandChildNavigationItemProps) => {
  return (
    <Box
      as="a"
      borderBottomRightRadius={'8px'}
      backgroundColor={isActive ? 'neutral.dark' : 'transparent'}
      _hover={{
        backgroundColor: 'neutral.dark',
        textDecoration: 'none'
      }}
      paddingX={4}
      paddingY={2}
      color="black"
      fontSize={pxToRem(13)}
      href={href}
      textDecoration="none"
      fontWeight="400"
      target={newTab ? '_blank' : '_self'}
      transition="background-color 0.3s ease-out"
      {...passThroughProps}
    >
      {label}
    </Box>
  );
};

export default GrandChildNavigationItem;
