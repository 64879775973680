import Layout from '../Layout';
import Block from '../Block';
import { ContentfulPageFragment } from '../../data/generated/graphql';
import Head from 'next/head';
import React from 'react';

const Page = (props: ContentfulPageFragment) => {
  const blocks = props.blocksCollection.items;
  const { title, metaDescription, shareImage, section, urlSlug } = props;

  // scroll to the hash section onload
  React.useEffect(() => {
    const path = window.location?.hash;
    if (path) {
      setTimeout(() => {
        const el = window.document.querySelector(path);
        if (el) {
          const r = el.getBoundingClientRect();

          const nav = window.document.querySelector('#navigation');
          const navBottom = nav.getBoundingClientRect();

          window.top.scroll({
            top: r.top - navBottom.bottom,
            behavior: 'smooth'
          });
        }
      }, 1000);
    }
  });

  return (
    <Layout
      title={title}
      metaDescription={metaDescription}
      ogImage={shareImage && shareImage.url ? shareImage.url : null}
      section={section}
      className={props.className}
    >
      {urlSlug === 'take-on-the-world' && (
        <Head>
          {/* <!-- Hotjar Tracking Code for https://nzte.takeontheworld.nz/ --> */}
          <script
            data-testid="hot-jar-script"
            dangerouslySetInnerHTML={{
              __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:2353825,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `
            }}
          />
        </Head>
      )}
      <div className="content-blocks">
        {blocks.map((block, key) => (
          <Block id={block.sys.id} typename={block.__typename} key={key} />
        ))}
      </div>
    </Layout>
  );
};

export default Page;
