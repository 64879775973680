import React from 'react';
import { Box, useDisclosure, Collapse, BoxProps } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import AnimatedChevronDown from '../../chakra-ui-components/Icon/AnimatedChevronDown/AnimatedChevronDown';

type MobileCollapseItemProps = {
  label: string;
  openAtStart?: boolean;
  onClick?: (isOpen) => void;
} & BoxProps;

const MobileCollapaseItem = ({
  label,
  children,
  openAtStart,
  onClick,
  ...passThroughProps
}: MobileCollapseItemProps) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: openAtStart });

  return (
    <Box {...passThroughProps}>
      <Box
        paddingX={4}
        paddingY={2}
        _hover={{ backgroundColor: 'neutral.dark' }}
        fontSize={pxToRem(15)}
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        as={'button'}
        border={0}
        borderBottomRightRadius="16px"
        backgroundColor={isOpen ? 'neutral.dark' : 'white'}
        onClick={() => {
          onToggle();
          onClick(isOpen);
        }}
      >
        <div>{label} </div>
        <AnimatedChevronDown
          marginLeft={0}
          width={pxToRem(10)}
          height={pxToRem(9)}
          isOpen={isOpen}
        />
      </Box>
      <Collapse in={isOpen} animateOpacity>
        <Box paddingY={4}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default MobileCollapaseItem;
