import { Box } from '@chakra-ui/react';
import MyAccountSvg from '../../../assets/svgs/my-account.svg';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { useRouter } from 'next/router';
import { UserData } from '../../../models/my-account';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import TextLink from '../navigation-items/TextLink';
import { MegaNavEvent, useNavTracking } from '../../../helpers/useGaTracking';

export type LoggedInPopoverContentProps = {
  user?: UserData;
};

const LoggedInPopoverContent = ({ user }: LoggedInPopoverContentProps) => {
  const router = useRouter();

  const currentSegment = router?.asPath ?? '';
  const navs = [
    { title: 'My profile', path: '/my-account/profile' },
    {
      title: 'My company',
      path: '/my-account/company'
    },
    {
      title: 'Personalisation',
      path: '/my-account/personalisation'
    },
    { title: 'Security', path: '/my-account/security' }
  ];

  return (
    <Box
      _before={{
        borderColor: 'transparent transparent #EDECE0',
        borderStyle: 'solid',
        borderWidth: '0 6px 6px',
        content: "''",
        height: 0,
        right: '19px',
        position: 'absolute',
        top: '-7px'
      }}
      boxShadow="0 2px 4px 0 rgba(0, 0, 0, 0.12)"
    >
      <Box
        display="flex"
        alignItems="center"
        height={18}
        lineHeight="base"
        whiteSpace="normal"
        bg="neutral.light"
        paddingX={3}
        borderBottomWidth="1px"
        borderBottomColor="neutral.dark"
      >
        <Box
          borderRadius="50%"
          height={10}
          w={10}
          border="1px solid black"
          pl={pxToRem(5)}
          pt={pxToRem(5)}
          color={'black'}
        >
          <Box as={MyAccountSvg} w={4} h={4} m={pxToRem(6)} />
        </Box>

        <Box pl={4} color="black">
          <Text fontWeight="bold" mb={1} fontSize={10}>
            Logged in as:
          </Text>
          <Text mb={0} fontWeight="400">
            {user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : ''}
          </Text>
        </Box>
      </Box>
      {navs.map((nav, index) => {
        if (nav.path === '/my-account/company' && !user?.withCompany) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            <TextLink
              label={nav.title}
              href={nav.path}
              isExternal={false}
              newTab={false}
              paddingY={4}
              paddingLeft={4}
              paddingRight={{ base: 16, md: 10 }}
              _hover={{
                bg: 'neutral.light',
                shadow: 'md',
                textDecoration: 'none'
              }}
              bg={currentSegment === nav.path ? 'neutral.light' : 'none'}
              onClick={() => {
                useNavTracking({
                  event: MegaNavEvent.LinkClick,
                  navMenuName: 'Log in',
                  navMenuIndex: 0,
                  navMenuLinkText: nav.title
                });
              }}
            />

            <Box borderBottomWidth={'1px'} borderBottomColor={'neutral.dark'} />
          </React.Fragment>
        );
      })}

      <TextLink
        label={'Log out'}
        href="/api/auth/logout"
        isExternal={true}
        newTab={false}
        paddingY={4}
        paddingLeft={4}
        paddingRight={{ base: 16, md: 10 }}
        _hover={{
          bg: 'neutral.light',
          shadow: 'md',
          textDecoration: 'none'
        }}
        key={`menu-item-log-out`}
        onClick={() => {
          useNavTracking({
            event: MegaNavEvent.LinkClick,
            navMenuName: 'Log in',
            navMenuIndex: 0,
            navMenuLinkText: 'Log out'
          });
        }}
      />
    </Box>
  );
};

export default LoggedInPopoverContent;
