import { useUser } from '@auth0/nextjs-auth0/client';
import { getUserPreferences } from '../../services/client/my-account';
import { useUserContext } from '../context/UserContext';

export const useUserPreference = () => {
  const { user: localUser, updateUserContext } = useUserContext();
  const { user } = useUser();

  if (!user) {
    return {
      user: null
    };
  }
  // if user logs in
  const getUserPreference = async () => {
    const userPreference = await getUserPreferences();
    updateUserContext({
      firstName: userPreference.firstName,
      lastName: userPreference.lastName,
      company: !!userPreference?.company
    });
  };

  if (user && !localUser) {
    getUserPreference();
  }

  return {
    user: localUser
  };
};
