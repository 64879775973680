import download from 'downloadjs';
import InternalLink from './InternalLink';
import { useEffect } from 'react';
import { ContentfulLinkFragment } from '../data/generated/graphql';

interface Props extends ContentfulLinkFragment {
  className?: string;
  children?: any;
  onUrlUpdate?: any;
}

const Link = (props: Props) => {
  const { className, children, onUrlUpdate } = props;
  let Component = null;

  let componentProps = {
    className,
    ...Link.getProps(props)
  };

  if (componentProps.href) Component = <a {...componentProps}>{children}</a>;
  else Component = <div {...componentProps}>{children}</div>;

  useEffect(() => {
    if (onUrlUpdate) onUrlUpdate(componentProps.href);
  }, []);

  return Component;
};

Link.getProps = (link) => {
  const {
    internalPage,
    internalPageQueryString,
    externalUrl,
    openInNewTab,
    file
  } = link;

  const props = {
    href: null,
    target: null,
    onClick: null,
    rel: null
  };

  if (internalPage || externalUrl) {
    if (internalPage) {
      props.href = InternalLink(internalPage);

      if (internalPageQueryString) props.href += internalPageQueryString;
    } else {
      props.href = externalUrl;
    }

    if (openInNewTab) {
      props.target = '_blank';
      props.rel = 'noopener';
    }
  } else if (file) {
    props.onClick = () => download(file.url);
  }

  return props;
};

export default Link;
