import {
  AspectRatio,
  Box,
  BoxProps,
  Text,
  useBreakpoint
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Document } from '@contentful/rich-text-types';
import ReactPlayer from 'react-player';
import { BaseChapterProps } from './Chapter';
import Grid from '../chakra-ui-components/Grid/Grid';
import { ChapterColumn } from './ChapterColumn';
import { MediaOverview } from './MediaOverview';
import { ContentfulBlockText } from '@nzte/website-stack-model';

export interface MediaProps {
  type: 'video' | 'audio';
  url: string;
  overview?: ContentfulBlockText;
  transcript?: Document;
  chapters?: BaseChapterProps[];
  caption?: string;
  hideChapters?: boolean;
}

const getWidth = (mediaHeight) => {
  return mediaHeight.current?.clientHeight;
};

const MediaPlayer = ({
  url,
  caption,
  width,
  height,
  onPlayerProgress,
  onPause,
  onPlay,
  isPlaying,
  mediaRef
}) => (
  <ReactPlayer
    onPause={onPause}
    onPlay={onPlay}
    caption={caption}
    onProgress={onPlayerProgress}
    playing={isPlaying}
    ref={mediaRef}
    url={url}
    width={width}
    height={height}
    controls
  />
);

export interface ContentfulChapter {
  label: string;
  marker: number;
}

/**
 * Some description here about my beautiful ButtonLine
 *
 */
export const Media = ({
  type,
  url,
  chapters,
  overview,
  transcript,
  caption,
  hideChapters,
  ...props
}: MediaProps & BoxProps) => {
  const screenSize = useBreakpoint('base');
  const mediaRef = React.useRef<ReactPlayer>(null);
  const [isPlaying, setPlaying] = React.useState(false);
  const [currentChapter, setCurrentChapter] = React.useState(0);
  const mediaHeight = useRef();
  const [currentChapterHeight, setChapterHeight] = useState(
    getWidth(mediaHeight)
  );
  useEffect(() => {
    setChapterHeight(getWidth(mediaHeight));
    const resizeListener = () => {
      setChapterHeight(getWidth(mediaHeight));
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const onPlayerProgress = ({
    playedSeconds
  }: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    chapters.forEach((c, i) => {
      if (i === chapters.length - 1 && c.marker <= playedSeconds) {
        setCurrentChapter(i);
        return;
      }

      if (c.marker <= playedSeconds && playedSeconds < chapters[i + 1].marker) {
        setCurrentChapter(i);
      }
    });
  };

  return (
    <Grid {...props}>
      <Box
        gridColumn={[
          'span 12',
          'span 12',
          'span 12',
          hideChapters ? 'span 12' : 'span 8'
        ]}
      >
        {type === 'video' && (
          <AspectRatio ratio={93 / 53}>
            <Box ref={mediaHeight} display="flex">
              <MediaPlayer
                url={url}
                caption={caption}
                mediaRef={mediaRef}
                onPause={() => {
                  setPlaying(false);
                }}
                isPlaying={isPlaying}
                onPlay={() => {
                  setPlaying(true);
                }}
                onPlayerProgress={onPlayerProgress}
                {...(type === 'video' && {
                  width: '100%',
                  height: '100%'
                })}
              />
            </Box>
          </AspectRatio>
        )}
        {type === 'audio' && (
          <Box
            ref={mediaHeight}
            display="flex"
            {...(type === 'audio' && {
              alignItems: 'center',
              justifyContent: 'center'
            })}
          >
            <MediaPlayer
              url={url}
              caption={caption}
              mediaRef={mediaRef}
              onPause={() => {
                setPlaying(false);
              }}
              isPlaying={isPlaying}
              onPlay={() => {
                setPlaying(true);
              }}
              onPlayerProgress={onPlayerProgress}
              {...(type === 'audio' && {
                width: '100%',
                height: '50px'
              })}
            />
          </Box>
        )}
        {caption && (
          <Text color="grey.02" mt={4}>
            {caption}
          </Text>
        )}
      </Box>
      {type === 'video' && chapters.length > 0 && (
        <ChapterColumn
          screenSize={screenSize}
          chapters={chapters}
          currentChapter={currentChapter}
          currentChapterHeight={currentChapterHeight}
          mediaRef={mediaRef}
          isPlaying={isPlaying}
          setPlaying={setPlaying}
        />
      )}
      <Box gridColumn={['span 12', 'span 8']}>
        {type === 'audio' && chapters.length > 0 && (
          <ChapterColumn
            screenSize={screenSize}
            chapters={chapters}
            currentChapter={currentChapter}
            currentChapterHeight={currentChapterHeight}
            mediaRef={mediaRef}
            isPlaying={isPlaying}
            setPlaying={setPlaying}
          />
        )}
        {overview && (
          <MediaOverview overview={overview} transcript={transcript} />
        )}
      </Box>
    </Grid>
  );
};
