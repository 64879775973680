import { Box } from '@chakra-ui/react';
import { Document } from '@contentful/rich-text-types';
import RichText from '../RichText';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '../chakra-ui-components/Tabs/Tabs';

interface OverviewProps {
  overview: any;
  transcript: Document;
}

export const MediaOverview = ({ overview, transcript }: OverviewProps) => {
  return (
    <Tabs>
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Transcript</Tab>
      </TabList>

      <TabPanels mt={6}>
        <TabPanel>
          <Box>
            <RichText text={{ json: overview.fields.body['en-US'] }} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            {' '}
            <RichText text={{ json: transcript }} />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
