import { useEffect, useContext } from 'react';
import SVG from 'react-inlinesvg';
import LazyLoad, { forceVisible } from 'react-lazyload';
import { Box, Image, Link, List, ListItem } from '@chakra-ui/react';
import { SectionContext } from './Section';
import LinkComponent from './Link';
import { pxToRem } from '../styles/chakra/theme-utils';
import { Text } from '@nzte/pallet-ui';
import Container from './chakra-ui-components/Container/Container';

const SOCIAL_MEDIA_ICONS = {
  facebook: '/icons/circle-fb-white.svg',
  twitter: '/icons/circle-tw-white.svg',
  linkedin: '/icons/circle-in-white.svg',
  youtube: '/icons/circle-yt-white.svg',
  instagram: '/icons/circle-ig-white.svg'
};

/**
 * Component
 *
 */
const Footer = () => {
  const section = useContext(SectionContext);

  let socialIcons =
    section &&
    section.socialMediaCollection &&
    section.socialMediaCollection.items &&
    section.socialMediaCollection.items.map(({ url, type }) => ({
      url,
      img: SOCIAL_MEDIA_ICONS[type]
    }));

  const LogoLink = (props) => {
    if (section?.footerLogoLink)
      return <LinkComponent {...section.footerLogoLink} {...props} />;

    return <Link href="/" {...props} />;
  };

  const Logo = () => {
    let logoProps = {
      src:
        section &&
        section.footerLogo &&
        section.footerLogo.url &&
        `${section.footerLogo.url}?h=80`,
      alt: section && section.footerLogo && section.footerLogo.title
    };

    if (!logoProps.src) logoProps.src = '/logos/nzte-greyscale.svg';
    if (!logoProps.alt) logoProps.alt = 'NZ Trade And Enterprise';

    return <Image height={14} {...logoProps} />;
  };

  const socialLabel =
    section && section.socialLabel ? section.socialLabel : 'Follow us:';

  const contactPhone = section && section.contactPhone;
  const contactEmail = section && section.contactEmail;

  useEffect(() => {
    setTimeout(() => {
      forceVisible();
    }, 1000);
  }, []);

  const FooterItem = ({ href, label }: { href: string; label: string }) => {
    return (
      <ListItem
        mt={0}
        display="inline-block"
        marginRight={6}
        _before={{ display: 'none' }}
      >
        <Link
          textStyle="pallet.eyebrow.regular"
          color="pallet_base.neutral.600"
          href={href}
          _hover={{ color: 'white' }}
        >
          {label}
        </Link>
      </ListItem>
    );
  };

  return (
    <LazyLoad once offset={500}>
      <Box
        as="footer"
        id="footer"
        overflow="hidden"
        background="black"
        color="white"
        mr="auto"
        __css={{
          a: {
            textDecoration: 'none !important',
            fontWeight: 'normal'
          }
        }}
      >
        <Box py={8} borderBottom="1px solid #333">
          <Container>
            <Box
              display={{ base: 'block', lg: 'flex' }}
              alignItems="center"
              gap={12}
            >
              {contactPhone && (
                <Box mb={{ base: 5, lg: 0 }} order={{ base: 1, lg: 2 }}>
                  <Text.Strong>General enquiry:</Text.Strong>
                  <Link
                    href={`tel:` + contactPhone.replace(/\s/g, '')}
                    display="block"
                    mt={1}
                    _hover={{ color: 'white' }}
                  >
                    {contactPhone}
                  </Link>
                </Box>
              )}

              {contactEmail && (
                <Box mb={{ base: 5, lg: 0 }} order={{ base: 2, lg: 3 }}>
                  <Text.Strong>Send us an enquiry:</Text.Strong>
                  <Link
                    href={`mailto:` + contactEmail.toLocaleLowerCase()}
                    display="block"
                    mt={1}
                    _hover={{ color: 'white' }}
                  >
                    {contactEmail}
                  </Link>
                </Box>
              )}

              {socialIcons && socialIcons.length > 0 && (
                <Box
                  mb={{ base: 5, lg: 0 }}
                  ml={{ base: 0, lg: 'auto' }}
                  mr={0}
                  order={{ base: 3, lg: 4 }}
                >
                  <Text.Strong>{socialLabel}</Text.Strong>
                  <Box display="block" mt={2}>
                    {socialIcons.map((icon, key) => (
                      <Link
                        key={key}
                        href={icon.url}
                        // className="footer__social-icon"
                        position="relative"
                        display="inline-block"
                        height="30px"
                        width="30px"
                        borderRadius="50%"
                        marginRight={2}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="contain"
                        target="_blank"
                        rel="noopener noreferrer"
                        _last={{
                          marginRight: 0
                        }}
                        _before={{
                          content: "''",
                          position: 'absolute',
                          display: 'block',
                          borderRadius: '50%',
                          transition: '0.3s ease-in all',
                          border: '1px solid white',
                          top: 0,
                          bottom: 0,
                          right: 0,
                          left: 0,
                          opacity: 0
                        }}
                        _hover={{
                          background: 'white',
                          _before: {
                            opacity: 1
                          },
                          'svg g, svg': {
                            fill: 'black'
                          },
                          'svg .icon-svg-stroke': {
                            stroke: 'white'
                          }
                        }}
                        verticalAlign={{ base: 'unset', lg: 'middle' }}
                      >
                        <Box
                          display="block"
                          borderRadius="50%"
                          width="100%"
                          height="100%"
                          as={SVG}
                          src={icon.img}
                        />
                      </Link>
                    ))}
                  </Box>
                </Box>
              )}

              <Box order={{ base: 4, lg: 1 }} mb={3}>
                <LogoLink>
                  <Logo />
                </LogoLink>
              </Box>
            </Box>
          </Container>
        </Box>

        <Container py={{ base: 0, lg: 2 }}>
          <Box
            display={{ base: 'block', lg: 'flex' }}
            justifyContent="space-between"
          >
            <List
              marginInlineStart={0}
              pl={0}
              gap={5}
              my={{ base: 4, lg: 'auto' }}
              order={{ base: 1, lg: 2 }}
            >
              <FooterItem href="/sitemap" label="Sitemap" />
              <FooterItem href="/page/privacy-policy" label="Privacy Policy" />
              <FooterItem
                href="/page/terms-of-use"
                label="Terms of Use & Engagement"
              />
            </List>

            <Link
              className="order-lg-12"
              href="https://www.govt.nz/"
              target="_blank"
              rel="noopener noreferrer"
              order={{ base: 2, lg: 3 }}
            >
              <Image
                height={pxToRem(50)}
                marginY={4}
                ml={{ base: 0, lg: 3 }}
                src="/logos/nzgovernment-white.svg"
                alt="New Zealand Government"
              />
            </Link>

            <Text.P
              textStyle="pallet.eyebrow.regular"
              color="pallet_base.neutral.600"
              my={{ base: 4, lg: 'auto' }}
              order={{ base: 3, lg: 1 }}
              maxW={{ base: '100%', lg: '37%' }}
            >
              {new Date().getFullYear()} New Zealand Trade and Enterprise. All
              rights reserved.
            </Text.P>
          </Box>
        </Container>
      </Box>
    </LazyLoad>
  );
};

export default Footer;
