import React from 'react';
import { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';
import ChevronUp from '/assets/svgs/chevron-up.svg';
import ChevronDown from '/assets/svgs/chevron-down.svg';
import ChevronRight from '/assets/svgs/chevron-right.svg';
import ChevronLeft from '/assets/svgs/chevron-left.svg';

interface ArrowProps extends BoxProps {
  direction: 'up' | 'down' | 'right' | 'left';
}

const Chevron = (props: ArrowProps) => {
  const { direction, color, ...passThroughProps } = props;
  let svg;
  switch (direction) {
    case 'up':
      svg = ChevronUp;
      break;
    case 'down':
      svg = ChevronDown;
      break;
    case 'right':
      svg = ChevronRight;
      break;
    case 'left':
      svg = ChevronLeft;
  }
  return (
    <Box
      as={svg}
      marginLeft={2}
      color={`${color || 'inherit'}`}
      {...passThroughProps}
    />
  );
};

export default Chevron;
