import React from 'react';
import { PageNavigationData } from '../../../data/navigation.data';
import {
  getHrefFromPageNavigationData,
  shouldOpenPageNavInNewTab
} from '../../../lib/utils/link';
import { Box, BoxProps, Flex, Text, forwardRef } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import { isNotEmptyArray } from '../../../lib/utils/assertions';
import AnimatedChevronDown from '../../chakra-ui-components/Icon/AnimatedChevronDown/AnimatedChevronDown';

type ChildNavigationItemProps = {
  item: PageNavigationData;
  onClick: (item: PageNavigationData) => void;
  isExpanded: boolean;
  isActive: boolean;
} & Omit<BoxProps, 'onClick'>;

const ChildNavigationItem = forwardRef(
  (props: ChildNavigationItemProps, ref) => {
    const { item, onClick, isExpanded, isActive, ...passThroughProps } = props;

    const hrefFromPageNavigationData = getHrefFromPageNavigationData(item);

    const newTab = shouldOpenPageNavInNewTab(item);

    return (
      <Box
        as={hrefFromPageNavigationData ? 'a' : 'button'}
        paddingX={0}
        paddingY={0}
        border={0}
        width={'fit-content'}
        _hover={{ color: 'grey.09', textDecoration: 'none' }}
        fontSize={pxToRem(15)}
        color={isExpanded || isActive ? 'grey.09' : 'black'}
        fontWeight="bold"
        onClick={() => onClick(item)}
        href={hrefFromPageNavigationData || undefined}
        target={hrefFromPageNavigationData ? (newTab ? '_blank' : '_self') : ''}
        _after={{
          display: 'block',
          content: '""',
          borderBottom: 'solid',
          borderBottomWidth: '2px',
          borderBottomColor: 'orange.70',
          transition: 'transform 0.2s ease-in',
          transform: isExpanded || isActive ? 'scaleX(1)' : 'scaleX(0)'
        }}
        sx={{
          '&:hover:after': {
            transformOrigin: '0% 50%',
            transform: 'scaleX(1)'
          }
        }}
        lineHeight="22px"
        ref={ref}
        {...passThroughProps}
      >
        <Flex alignItems={'center'} paddingBottom={6}>
          <Text padding={0} marginBottom={0} fontWeight="inherit">
            {item.label}
          </Text>
          {!hrefFromPageNavigationData && isNotEmptyArray(item.children) && (
            <AnimatedChevronDown
              isOpen={isExpanded}
              marginLeft={3}
              width={2}
              height={pxToRem(5)}
            />
          )}
        </Flex>
      </Box>
    );
  }
);

export default ChildNavigationItem;
