import { useState } from 'react';
import ReactPlayer from 'react-player';
import { marked } from 'marked';
import { ContentfulVideoIframeFragmentFragment } from '../../../data/generated/graphql';
import { VideoIframeButton } from './VideoIframeButton';
import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends ContentfulVideoIframeFragmentFragment {
  showCaption?: boolean;
}

const aspectRatioPaddingTopList = {
  '21by9': '42.86%',
  '16by9': '56.25%',
  '4by3': '75%',
  '1by1': '100%'
};

const VideoIframe = (videoIframeProps: Props & BoxProps) => {
  const [play, setPlay] = useState<boolean>(false);
  const {
    caption,
    url,
    viewTime,
    showCaption = true,
    ...props
  } = videoIframeProps;

  // Get aspect ratio class
  const aspectRatioPaddingTop = ['21by9', '16by9', '4by3', '1by1'].includes(
    videoIframeProps.aspectRatio
  )
    ? aspectRatioPaddingTopList[videoIframeProps.aspectRatio]
    : '56.25%';

  return (
    <Box mt={3} mb={5} {...props}>
      <Box
        bg="black"
        display="block"
        overflow="hidden"
        padding={0}
        position="relative"
        width="100%"
        __css={{
          '.react-player__play-icon': {
            display: 'none !important'
          },
          ':before': {
            content: "''",
            display: 'block',
            paddingTop: aspectRatioPaddingTop
          },
          '> div': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            border: 0
          }
        }}
      >
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing={play}
          controls={true}
          light={!play}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                rel: 0,
                showinfo: 0
              }
            }
          }}
        />
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display={play ? 'none' : 'flex'}
          bg="rgba(0,0,0,.35)"
          cursor="pointer"
          onClick={() => setPlay(true)}
        >
          <VideoIframeButton viewTime={viewTime} />
        </Box>
      </Box>

      {caption && showCaption && (
        <Box
          fontSize="14.4px"
          color="grey.01"
          mt={2}
          __css={{
            'p:last-child': {
              marginBottom: 0
            }
          }}
          dangerouslySetInnerHTML={{ __html: marked.parse(caption) }}
        />
      )}
    </Box>
  );
};

export default VideoIframe;
