import React, { useContext } from 'react';
import { NavigationModuleData } from '../data/navigation.data';
import { UserPreference } from '../models/my-account';

export interface ServerSideRenderingDataInterface {
  PAGE_LAYOUT: any;

  PAGE_QUERY?: any;

  PAGE_NAVIGATION_MODULE_DATA: NavigationModuleData;

  PAGE_404: any;

  PAGE_LIVE_DEAL_DETAILS_QUERY?: any;
  PAGE_LIVE_DEAL_DETAILS_PARENT_PAGE_QUERY?: any;

  SITEMAP_QUERY?: any;
  HOMEPAGE_QUERY?: any;
  PAGE_BLOCKS: any;
  BLOCK_ACCORDIONS: any;

  PAGE_DEAL_LANDING_QUERY?: any;
  DEAL_LISTING_QUERY?: any;

  LIVE_DEAL_FILTERS_QUERY?: any;

  DROPDOWN_OPTIONS_QUERY?: any;

  USER_PREFERENCES?: UserPreference;

  LISTING_QUERY?: any;
  FILTER_QUERY?: any;
  DETAIL_QUERY?: any;

  EMBED_BLOCK?: any;
  DEFAULT_AIP_HERO_QUERY?: any;

  LIVE_DEALS_FORM_QUERY?: any;
}

export const serverSideRenderingEmptyData: ServerSideRenderingDataInterface = {
  PAGE_LAYOUT: {},
  PAGE_NAVIGATION_MODULE_DATA: { navigation: { main: [] }, header: '', id: '' },
  PAGE_404: {},
  PAGE_LIVE_DEAL_DETAILS_QUERY: {},
  PAGE_LIVE_DEAL_DETAILS_PARENT_PAGE_QUERY: {},
  PAGE_QUERY: {},
  HOMEPAGE_QUERY: {},
  SITEMAP_QUERY: {},
  PAGE_BLOCKS: {},
  BLOCK_ACCORDIONS: {},
  PAGE_DEAL_LANDING_QUERY: {},
  DEAL_LISTING_QUERY: {},

  LIVE_DEAL_FILTERS_QUERY: {},
  DROPDOWN_OPTIONS_QUERY: {},
  USER_PREFERENCES: {},

  LISTING_QUERY: {},
  FILTER_QUERY: {},
  DETAIL_QUERY: {},

  EMBED_BLOCK: {},
  LIVE_DEALS_FORM_QUERY: {}
};

/**
 * Context
 *
 */
export const ServerSideRenderingDataContext = React.createContext(
  serverSideRenderingEmptyData
);

/**
 * Use context consumer hook
 *
 */
export const useServerSideRenderingDataContext = () =>
  useContext(ServerSideRenderingDataContext);
