import React from 'react';
import { NavigationItemType } from '../../../models/navigation-models';
import { PageNavigationData } from '../../../data/navigation.data';
import { Box, DrawerProps, Text, VStack } from '@chakra-ui/react';
import { isNotEmptyArray } from '../../../lib/utils/assertions';
import MobileBoxLink from '../mobile-navigation-items/MobileBoxLink';
import {
  getHrefFromPageNavigationData,
  isPageNavigationExternalLink,
  shouldOpenPageNavInNewTab
} from '../../../lib/utils/link';
import ChevronLeftIcon from '../../../assets/svgs/chevron-left.svg';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import MobileTextLink from '../mobile-navigation-items/MobileTextLink';
import MobileCollapaseItem from '../mobile-navigation-items/MobileCollapaseItem';
import { MegaNavEvent, useNavTracking } from '../../../helpers/useGaTracking';

type MobileChildNavigationDrawerProps = {
  type:
    | NavigationItemType.PRIMARY_CHILDREN
    | NavigationItemType.SECONDARY_DROPDOWN;
  items: PageNavigationData[];
  onBackButtonClick: () => void;
  backButtonLabel: string;
  header: string;
  logIn?: PageNavigationData[];
  onClose: () => void;
  isOpen: boolean;
  isUserLoggedIn?: boolean;
  index?: number;
} & Omit<DrawerProps, 'children'>;

const MobileChildNavigationDrawer = (
  props: MobileChildNavigationDrawerProps
) => {
  const {
    type,
    items,
    onBackButtonClick,
    backButtonLabel,
    header,
    isUserLoggedIn,
    index: drawerIndex
  } = props;

  return (
    <Box h={'full'} bgGradient="linear(to-b, black, grey.13)">
      <VStack
        w={'full'}
        h={'full'}
        bgImage={`url('/images/wave-pattern.svg')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        backgroundSize="cover"
      >
        <Box padding={6} width="100%" height="100%" overflowY={'auto'}>
          {backButtonLabel && (
            <Box
              color="white"
              as={'button'}
              padding={0}
              onClick={onBackButtonClick}
              backgroundColor={'transparent'}
              margin={0}
              fontWeight="bold"
              fontSize={'1rem'}
              marginBottom={4}
              display={'flex'}
              alignItems={'center'}
            >
              <Box
                as={ChevronLeftIcon}
                width={pxToRem(10)}
                height={pxToRem(8)}
                marginRight={2}
              />
              {backButtonLabel}
            </Box>
          )}

          <Text
            fontWeight="bold"
            fontSize="1.5rem"
            lineHeight="1.875rem"
            letterSpacing={'-0.2px'}
            color="white"
          >
            {header}
          </Text>
          <Box
            height={0}
            borderBottomWidth={isNotEmptyArray(items) ? '1px' : 0}
            borderColor={'white'}
            marginTop={4}
            marginBottom={6}
          />

          {items?.map((link, index) => {
            if (link?.hidenAfterLogin && isUserLoggedIn) {
              return <></>;
            } else {
              if (type === NavigationItemType.SECONDARY_DROPDOWN) {
                return (
                  <MobileTextLink
                    label={link.label}
                    href={getHrefFromPageNavigationData(link)}
                    marginBottom={4}
                    isActive={link.active}
                    newTab={shouldOpenPageNavInNewTab(link)}
                    isExternal={isPageNavigationExternalLink(link)}
                    onClick={() => {
                      useNavTracking({
                        event: MegaNavEvent.LinkClick,
                        navMenuName: header,
                        navMenuIndex: drawerIndex,
                        navMenuLinkText: link.label
                      });
                    }}
                  />
                );
              }

              return isNotEmptyArray(link.children) ? (
                <MobileCollapaseItem
                  label={link.label}
                  key={index}
                  marginBottom={4}
                  onClick={(isOpen) => {
                    useNavTracking({
                      event: !isOpen
                        ? MegaNavEvent.OpenMenu
                        : MegaNavEvent.CloseMenu,
                      navMenuName: link.label,
                      navMenuIndex: index
                    });
                  }}
                >
                  {link.children.map((child, childIndex) => {
                    if (child?.hidenAfterLogin && isUserLoggedIn) {
                      return <></>;
                    } else {
                      return (
                        <MobileTextLink
                          label={child.label}
                          href={getHrefFromPageNavigationData(child)}
                          key={childIndex}
                          marginBottom={
                            childIndex === link.children.length - 1 ? 2 : 6
                          }
                          isActive={link.active}
                          newTab={shouldOpenPageNavInNewTab(link)}
                          isExternal={isPageNavigationExternalLink(link)}
                          onClick={() => {
                            useNavTracking({
                              event: MegaNavEvent.LinkClick,
                              navMenuName: link.label,
                              navMenuIndex: index,
                              navMenuLinkText: child.label
                            });
                          }}
                        />
                      );
                    }
                  })}
                </MobileCollapaseItem>
              ) : (
                <MobileBoxLink
                  label={link.label}
                  href={getHrefFromPageNavigationData(link)}
                  key={index}
                  marginBottom={4}
                  isActive={link.active}
                  newTab={shouldOpenPageNavInNewTab(link)}
                  isExternal={isPageNavigationExternalLink(link)}
                  onClick={() => {
                    useNavTracking({
                      event: MegaNavEvent.LinkClick,
                      navMenuName: link.label,
                      navMenuIndex: index,
                      navMenuLinkText: link.label
                    });
                  }}
                />
              );
            }
          })}
        </Box>
      </VStack>
    </Box>
  );
};

export default MobileChildNavigationDrawer;
