import {
  Select,
  Tab as CTab,
  TabList as CTablist,
  TabListProps,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs as CTabs,
  TabsProps as CTabsProps,
  useBreakpoint,
  useTabs
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';
export { TabPanels, TabPanel };

interface TabsProps extends Omit<CTabsProps, 'onChange' | 'size'> {
  onChange?: (index: number) => void;
  children: React.ReactNode;
  showUnderline?: boolean;
}

/**
 * Hijacking Tabs component to remove any styles and add our own
 */
export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  (props: TabsProps, ref) => {
    const { onChange, children, showUnderline, ...rest } = props;
    return (
      <CTabs
        data-test-id="tabs"
        ref={ref}
        variant="unstyled"
        onChange={onChange}
        flex={1}
        borderBottom={showUnderline && '4px solid'}
        borderBottomColor={showUnderline && 'grey.04'}
        {...rest}
      >
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return;
          }

          return React.cloneElement(child, { ...rest, ...{ onChange } });
        })}
      </CTabs>
    );
  }
);

/**
 * Hijacking Tab component to add our own styles
 */
export const Tab = forwardRef<HTMLButtonElement, TabProps>((props, ref) => {
  return (
    <CTab
      data-test-id="tabs-tab"
      ref={ref}
      fontSize={['sm', 'sm', 'lg']}
      fontWeight={700}
      border="none"
      borderBottom="solid"
      borderBottomWidth={['2px', '2px', '4px']}
      borderBottomColor="transparent"
      background="transparent"
      px={['12px', '12px', '22px']}
      paddingTop={'4px'}
      paddingBottom="6px"
      _selected={{ color: 'primary.01', borderBottomColor: 'primary.01' }}
      {...props}
    />
  );
});

/**
 * Hijacking TabList component to display a Select on mobile
 * We need to get TabContext
 */
interface TabListPropsExt extends TabListProps {
  hasMobileSelectBox?: boolean;
}
export const TabList: React.FC<TabListPropsExt> = forwardRef(
  ({ hasMobileSelectBox, children, ...props }, ref) => {
    const isScreenSizeBase = useBreakpoint('base');

    const { setSelectedIndex, selectedIndex } = useTabs(props as any);

    if (isScreenSizeBase && hasMobileSelectBox) {
      const options = React.Children.map(children, (child, i) => {
        if (!React.isValidElement(child)) {
          return;
        }

        return {
          name: child.props.children,
          value: `${i}`
        };
      });

      return (
        <Select
          placeholder="Options"
          onChange={(v) => setSelectedIndex(Number(v.target.value))}
          value={options.find((o) => o.value === `${selectedIndex}`).value}
        >
          {options.map(({ name, value }) => (
            <option value={value}>{name}</option>
          ))}
        </Select>
      );
    }

    return (
      <CTablist
        data-test-id="tabs-tablist"
        ref={ref}
        children={children}
        {...props}
      />
    );
  }
);
