/**
 * Receives an entry and resolves the url
 *
 */

interface Props {
  __typename?: string;
  urlSlug?: string;
  key?: string;
}

const PAGE_KEY_URLS = {
  'livedeals-logged-in-user': '/livedeals/about',
  'livedeals-logged-out-user': '/livedeals/about',
  'live-deal-my-account': '/my-account/profile'
};

const InternalLink = (entry: Props) => {
  const { __typename, urlSlug, key } = entry;

  if (__typename === 'Page') {
    if (urlSlug) {
      return `/page/${urlSlug}`;
    } else if (key && PAGE_KEY_URLS[key]) {
      return PAGE_KEY_URLS[key];
    }
  } else if (__typename === 'BlogEntry') {
    if (urlSlug) {
      return `/blog/${urlSlug}`;
    }
  } else if (__typename === 'FormPage') {
    if (urlSlug) {
      return `/form/${urlSlug}`;
    }
  } else if (__typename === 'PageLiveDealDetails') {
    if (urlSlug) {
      return `/livedeals/${urlSlug}`;
    }
  }

  return null;
};

export default InternalLink;
