import { Box, Link } from '@chakra-ui/react';
import { ButtonInterface } from './Button/Button';
import { pxToRem } from '../styles/chakra/theme-utils';

const QuickLinks = (props: ButtonInterface) => {
  const { label, variant, link, onClick, subtext, style } = props;
  const isPallet = style === 'Pallet';
  const {
    color,
    colorActive,
    background,
    backgroundActive,
    border,
    borderActive
  } =
    variant === 'white'
      ? {
          color: 'black',
          colorActive: 'black',
          background: 'white',
          backgroundActive: '#f3f3f3',
          border: 'white',
          borderActive: '#f3f3f3'
        }
      : {
          color: 'white',
          colorActive: 'white',
          background: 'black',
          backgroundActive: '#2e2e2e',
          border: 'black',
          borderActive: '#2e2e2e'
        };

  const child = (
    <Box
      as="span"
      color="inherit"
      position="relative"
      background="black"
      marginBottom={isPallet && { base: 5, md: 4 }}
      marginY={!isPallet && { base: 1, md: pxToRem(14) }}
      marginX={isPallet ? 0 : { base: 1, md: pxToRem(14) }}
      borderRadius={200}
      height={15}
      display="flex"
      paddingY={isPallet ? 0 : 2}
      pl={pxToRem(30)}
      pr={pxToRem(70)}
      transitionDuration="0.3s"
      transitionTimingFunction="ease-in"
      transitionProperty="border"
      backgroundColor={background}
      _hover={{
        '>span>span': {
          transform: 'rotate(-0deg)'
        }
      }}
    >
      <Box
        as="span"
        marginX={0}
        my="auto"
        lineHeight={isPallet ? pxToRem(21) : pxToRem(17.6)}
      >
        {label}{' '}
        <Box as="span" fontWeight="400">
          {' '}
          {subtext && subtext}
        </Box>
      </Box>

      <Box
        as="span"
        display="flex"
        margin="auto"
        height={9}
        width={pxToRem(50)}
        borderLeft="1px solid"
        position="absolute"
        right={pxToRem(5)}
        top={0}
        bottom={0}
        borderColor={color}
      >
        <Box
          as="span"
          margin="auto"
          height={5}
          width={5}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="50%"
          transform="rotate(-45deg)"
          transitionDuration="0.3s"
          transitionTimingFunction="ease-in"
          transitionProperty="all"
          backgroundImage={
            color === 'black'
              ? 'url("/icons/arrow-black.svg")'
              : 'url("/icons/arrow.svg")'
          }
        />
      </Box>
    </Box>
  );

  return (
    <Box
      as={link && Link}
      {...(link && link)}
      className="quicklink-btn"
      onClick={onClick}
      display="block"
      border={isPallet ? 'none' : '1px solid'}
      borderRadius={200}
      textDecoration="none !important"
      fontWeight="bold"
      fontSize={isPallet ? pxToRem(14) : { base: pxToRem(14), md: pxToRem(16) }}
      transitionDuration="0.3s"
      transitionTimingFunction="ease-in"
      transitionProperty="border"
      paddingY={0}
      mb={4}
      color={color}
      borderColor={isPallet ? 'unset' : border}
      width="100%"
      css={{
        '&:hover': {
          color: colorActive,
          borderColor: isPallet ? 'unset' : borderActive,
          '>span': {
            backgroundColor: backgroundActive
          }
        },
        '&:focus': {
          color: colorActive,
          borderColor: isPallet ? 'unset' : borderActive,
          '>span': {
            backgroundColor: backgroundActive
          }
        }
      }}
    >
      {child}
    </Box>
  );
};

export default QuickLinks;
