import React from 'react';
import { BoxProps } from '@chakra-ui/react';
import { PageNavigationData } from '../../../data/navigation.data';
import { Box } from '@chakra-ui/layout';
import TextLink from '../navigation-items/TextLink';
import {
  getHrefFromPageNavigationData,
  isPageNavigationExternalLink,
  shouldOpenPageNavInNewTab
} from '../../../lib/utils/link';
import { MegaNavEvent, useNavTracking } from '../../../helpers/useGaTracking';

export type LoggedOutPopoverContentProps = {
  loginSubMenu?: Array<PageNavigationData>;
} & BoxProps;

const LoggedOutPopoverContent = ({
  loginSubMenu,
  ...passThroughProps
}: LoggedOutPopoverContentProps) => {
  return (
    <Box padding={0} {...passThroughProps}>
      {loginSubMenu?.map((item, index) => (
        <React.Fragment key={index}>
          <TextLink
            label={item.label}
            href={getHrefFromPageNavigationData(item)}
            isExternal={isPageNavigationExternalLink(item)}
            newTab={shouldOpenPageNavInNewTab(item)}
            paddingY={4}
            paddingLeft={4}
            paddingRight={{ base: 16, md: 10 }}
            _hover={{
              bg: 'neutral.light',
              shadow: 'md',
              textDecoration: 'none'
            }}
            bg={item.active ? 'neutral.light' : 'none'}
            onClick={() => {
              useNavTracking({
                event: MegaNavEvent.LinkClick,
                navMenuName: 'Log in',
                navMenuIndex: 0,
                navMenuLinkText: item.label
              });
            }}
          />
          {index !== loginSubMenu?.length - 1 && (
            <Box borderBottomWidth={'1px'} borderBottomColor={'neutral.dark'} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default LoggedOutPopoverContent;
