import React from 'react';
import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import {
  useButtonVariantStyles,
  VariantStylesType
} from '../../../lib/hooks/useButtonVariantStyles';

export type ButtonProps = {
  label?: string;
  variant?: VariantStylesType;
  disabled?: boolean;
  onClick?: (event?: React.MouseEvent) => void;
  href?: string;
  as?: React.ElementType;
  to?: string;
  brandColor?: string;
  brandColorHover?: string;
  brandColorHoverLight?: string;
  brandColorHoverMedium?: string;
  brandColorHoverDark?: string;
  target?: string;
  children?: React.ReactNode;
};

/**
 *
 * @param Component Some auto props for button and link
 */
const generatePropsFor = (Component: React.ElementType) => {
  switch (Component) {
    case 'button':
      return { type: 'button ' };
    case 'a':
      return { target: '_blank', rel: 'noopener noreferrer' };
    default:
      return {};
  }
};

/**
 * Some description here about my beautiful button
 *
 */
const Button = forwardRef<ButtonProps & BoxProps, 'button'>(
  (
    {
      variant = 'full-dark',
      disabled,
      as: Component = 'button',
      brandColor = 'black',
      brandColorHover = 'grey.13',
      brandColorHoverLight = 'grey.13',
      brandColorHoverMedium = 'grey.13',
      brandColorHoverDark = 'grey.13',
      target,
      label = '',
      children,
      id,
      ...props
    }: ButtonProps & BoxProps,
    ref
  ) => {
    const componentProps = generatePropsFor(Component);
    const buttonStyleProps = useButtonVariantStyles({
      variant,
      brandColor,
      brandColorHover,
      brandColorHoverLight,
      brandColorHoverDark,
      brandColorHoverMedium,
      withChildren: children ? true : false
    });
    const testId = id ? `${id.toLocaleLowerCase()}-btn` : '';

    return (
      <Box
        data-test-id={testId}
        label={label}
        as={Component}
        target={target}
        disabled={disabled}
        py="9px"
        px={6}
        {...buttonStyleProps}
        {...componentProps}
        ref={ref}
        userSelect="none"
        {...props}
      >
        {children ? children : variant.includes('outlined') ? '' : label}
      </Box>
    );
  }
);

export default Button;
