import { useState } from 'react';
import ProfilePerson from './ProfilePerson';
import ModalPersonProfiles from './Modals/ModalPersonProfiles';
import { ContentfulProfilePersonFragmentFragment } from '../data/generated/graphql';

export interface ProfilePersonModalInterface {
  entry: ContentfulProfilePersonFragmentFragment;
}

const ProfilePersonModal = (props) => {
  const { entry } = props;
  const [activeProfile, setActiveProfile] = useState<number>(1);
  return (
    <div className="container py-3">
      <ProfilePerson {...entry} onClick={() => setActiveProfile(0)} />
      <ModalPersonProfiles
        profiles={[entry]}
        show={activeProfile !== 1}
        profileIndex={activeProfile}
        updateProfileIndex={(val) => setActiveProfile(val)}
        onClosed={() => setActiveProfile(1)}
      />
    </div>
  );
};

export default ProfilePersonModal;
