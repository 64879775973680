import { Box } from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import EventListener from 'react-event-listener';
import { animateScroll as scroll } from 'react-scroll';
import { pxToRem } from '../../styles/chakra/theme-utils';
import BackToTopSvg from '../../public/icons/back-to-top.svg';

/**
 * Component
 *
 */
const BackToTop = ({ showButton = false }) => {
  const [show, setShow] = useState<boolean>(showButton ?? false);
  const [marginBottom, setMarginBottom] = useState<number>(0);
  const ref = useRef(null);

  // Check scroll position
  const checkPosition = () => {
    const { scrollY, innerHeight } = window;

    if (scrollY > 100) {
      const footer = document.getElementById('footer');
      let marginBottom = 0;

      if (footer) {
        marginBottom = scrollY + innerHeight - footer.offsetTop;
        marginBottom = marginBottom - ref.current?.offsetHeight;
        marginBottom = marginBottom > 0 ? marginBottom : 0;
      }

      setShow(true);
      setMarginBottom(marginBottom);
    } else if (showButton) {
      setShow(true);
      setMarginBottom(0);
    } else {
      setShow(false);
      setMarginBottom(0);
    }
  };

  // Check position on load
  useEffect(() => checkPosition(), []);
  return (
    <>
      <EventListener
        target="window"
        onResize={checkPosition}
        onScroll={checkPosition}
      />
      {show && (
        <Box
          ref={ref}
          cursor="pointer"
          h={pxToRem(60)}
          w={pxToRem(70)}
          position="fixed"
          bottom={pxToRem(20)}
          right={pxToRem(20)}
          transition="all 0.3s ease-in"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="100%"
          zIndex={10}
          _hover={{
            opacity: 0.75
          }}
          onClick={() => scroll.scrollToTop({ duration: 750 })}
          style={{ marginBottom }}
        >
          <BackToTopSvg />
        </Box>
      )}
    </>
  );
};

export default BackToTop;
