import { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { marked } from 'marked';
import SVG from 'react-inlinesvg';
import { useRouter } from 'next/router';
import Carousel from '../Carousel';
import VideoIframe from '../chakra-ui-components/Video/VideoIframe';
import ButtonLink from '../Button/ButtonLink';
import Share from '../Share';
import { ContentfulProfilePersonFragmentFragment } from '../../data/generated/graphql';

/**
 * Components
 *
 */
export interface Props {
  profiles: ContentfulProfilePersonFragmentFragment[];
  show: boolean;
  profileIndex?: number;
  onClosed: Function;
  updateProfileIndex?: Function;
}

const ModalPersonProfiles = (props: Props) => {
  const router = useRouter();
  const [shareUrlBase, setShareUrlBase] = useState<string>(null);
  const { show, profiles, profileIndex, onClosed, updateProfileIndex } = props;

  useEffect(() => {
    const { query } = router;

    if (query?.profile) {
      let queryProfile =
        typeof query.profile === 'string' ? query.profile : query.profile[0];

      if (queryProfile) {
        let activeProfileIndex = null;

        profiles.forEach((profile, index) => {
          if (!activeProfileIndex && queryProfile === profile.sys.id)
            activeProfileIndex = index;
        });

        if (activeProfileIndex !== null && updateProfileIndex)
          updateProfileIndex(activeProfileIndex);
      }
    }

    setShareUrlBase(
      window.location.origin + window.location.pathname + '?profile='
    );
  }, []);

  // Get carousel items
  const items = profiles.map((profile) => {
    if (!profile) return;
    const {
      modalImage,
      name,
      byline,
      focusArea,
      focusSector,
      linkedInUrl,
      video,
      content,
      location
    } = profile;
    const buttons = profile.buttonsCollection?.items;

    // Generate image div
    let Image =
      modalImage && modalImage.url ? (
        <div
          className="modal-person-profiles__profile-img"
          style={{
            backgroundImage: `url(${modalImage.url}?w=540&h=650&fit=fill)`
          }}
        />
      ) : null;

    // Generate details
    const className = !Image ? 'no-image' : '';

    return (
      <div className={`modal-person-profiles__profile ${className}`}>
        {Image}

        <div className="modal-person-profiles__profile-body">
          <div>
            <div className="modal-person-profiles__profile-name">{name}</div>
            {byline && (
              <div className="modal-person-profiles__profile-byline">
                {byline}
              </div>
            )}

            {location && (
              <p>
                <b>Location</b>
                <br />
                <span>{location}</span>
              </p>
            )}

            {focusArea && (
              <p>
                <b>Focus area</b>
                <br />
                <span>{focusArea}</span>
              </p>
            )}

            {focusSector && (
              <p>
                <b>Focus sector</b>
                <br />
                <span>{focusSector}</span>
              </p>
            )}

            {linkedInUrl && (
              <a
                className="modal-person-profiles__profile-linkedin"
                href={linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SVG src="/icons/circle-in-white.svg" />
              </a>
            )}

            {video && <VideoIframe {...video} />}

            {content && (
              <div
                className="modal-person-profiles__profile-content"
                dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
              />
            )}

            {buttons &&
              buttons.length > 0 &&
              buttons.map((btn, key) => <ButtonLink {...btn} key={key} />)}

            {shareUrlBase && (
              <div className="text-right">
                <Share.CopyLink url={`${shareUrlBase}${profile.sys.id}`} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <Modal
      isOpen={show}
      size="xl"
      className="modal-person-profiles"
      onClosed={onClosed}
    >
      <button className="close" onClick={() => onClosed()} />
      <Carousel
        initialIndex={profileIndex}
        items={items}
        swipeable={true}
        textIndicator={false}
      />
    </Modal>
  );
};

export default ModalPersonProfiles;
