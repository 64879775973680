import React from 'react';
import { useServerSideRenderingDataContext } from '../ServerSideRenderingDataContext';
import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import { useRouter } from 'next/router';
import { SITE_SEARCH } from '../../lib/settings';
import { useUserPreference } from '../../lib/hooks/useUserPreference';
import { Box } from '@chakra-ui/react';

const Navigation = () => {
  const dataContext = useServerSideRenderingDataContext();

  const {
    header,
    headerLink,
    navigationHeaderLogo,
    mobileHeaderLogo,
    navigation
  } = dataContext.PAGE_NAVIGATION_MODULE_DATA;

  const { user } = useUserPreference();

  const userPrefData = user
    ? {
        firstName: user.firstName,
        lastName: user.lastName,
        withCompany: !!user?.company
      }
    : undefined;

  const router = useRouter();

  const isHomePage = router.pathname === '/';

  return (
    <Box id="navigation">
      <NavigationMobile
        navigation={navigation}
        header={header || 'Explore NZTE'}
        headerLink={headerLink}
        headerLogo={mobileHeaderLogo}
        userPrefData={userPrefData}
        isUserLoggedIn={!!user}
        isHomePage={isHomePage}
        isSiteSearchEnabled={SITE_SEARCH}
      />
      <NavigationDesktop
        navigation={navigation}
        header={header}
        headerLink={headerLink}
        headerLogo={navigationHeaderLogo}
        userPrefData={userPrefData}
        isUserLoggedIn={!!user}
        isHomePage={isHomePage}
        isSiteSearchEnabled={SITE_SEARCH}
      />
    </Box>
  );
};

export default Navigation;
