import { Grid as CGrid, GridProps } from '@chakra-ui/react';
import React from 'react';

interface GridContainerProps {
  templateColumns?: GridProps['templateColumns'];
  gap?: GridProps['gap'];
  rowGap?: GridProps['rowGap'];
  columnGap?: GridProps['columnGap'];
  autoFlow?: GridProps['autoFlow'];
  autoRows?: GridProps['autoRows'];
  autoColumns?: GridProps['autoColumns'];
  templateRows?: GridProps['templateRows'];
  templateAreas?: GridProps['templateAreas'];
}

type Props = GridContainerProps & GridProps;

export const Grid = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    return (
      <CGrid
        ref={ref}
        templateColumns="repeat(12, 1fr)"
        gap={{ base: 2, lg: 6 }}
        rowGap={{ base: 8, lg: 10 }}
        {...props}
      ></CGrid>
    );
  }
);

interface GridItemProps {
  column?: GridProps['column'];
  row?: GridProps['row'];
}

export const GridItem = ({ ...props }: GridItemProps & GridProps) => {
  return <CGrid {...props}></CGrid>;
};

export default Grid;
