import { HeadingProps as CHeadingProps } from '@chakra-ui/react';
import { Text } from '@nzte/pallet-ui';

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps extends CHeadingProps {
  type: HeadingType;
}

const Heading = ({ children, type, ...props }: HeadingProps) => {
  switch (type) {
    case 'h1':
      return (
        <Text.H1
          textStyle="pallet.title.4xl.bold"
          mb="pallet.interior.lg"
          fontFamily={`'Antonio', sans-serif !important`}
          fontSize={{ base: 34, lg: 52 }}
          {...props}
        >
          {children}
        </Text.H1>
      );
    case 'h2':
      return (
        <Text.H2
          textStyle="pallet.title.3xl.bold"
          mb="pallet.interior.lg"
          {...props}
        >
          {children}
        </Text.H2>
      );
    case 'h3':
      return (
        <Text.H3
          textStyle="pallet.title.2xl.bold"
          mb="pallet.interior.md"
          {...props}
        >
          {children}
        </Text.H3>
      );
    case 'h4':
      return (
        <Text.H4
          textStyle="pallet.title.xl.bold"
          mb="pallet.interior.lg"
          {...props}
        >
          {children}
        </Text.H4>
      );
    case 'h5':
      return (
        <Text.H5
          textStyle="pallet.title.md.bold"
          mb="pallet.interior.lg"
          {...props}
        >
          {children}
        </Text.H5>
      );
    default:
      return (
        <Text.H6
          textStyle="pallet.title.2xs.bold"
          mb="pallet.interior.md"
          {...props}
        >
          {children}
        </Text.H6>
      );
  }
};

export default Heading;
