import React, { useState } from 'react';
import {
  Flex,
  Grid,
  HStack,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import HamburgerIcon from '../../assets/svgs/hamburger.svg';
import CloseIcon from '../../assets/svgs/close.svg';
import { PageNavigationData } from '../../data/navigation.data';
import { useDisclosure } from '@chakra-ui/hooks';
import { isNotEmptyArray } from '../../lib/utils/assertions';
import NavigationLoginButton from './NavigationLoginButton/NavigationLoginButton';
import { Box } from '@chakra-ui/layout';
import { NavigationProps } from './model';
import MobileMainNavigationDrawer from './mobile-navigation-drawers/MobileMainNavigationDrawer';
import MobileChildNavigationDrawer from './mobile-navigation-drawers/MobileChildNavigationDrawer';
import { NavigationItemType } from '../../models/navigation-models';
import NzteLogo from './navigation-items/NzteLogo';
import SiteSearchEmbedded from '../SiteSearchEmbedded';
import { MegaNavEvent, useNavTracking } from '../../helpers/useGaTracking';

const NavigationMobile = ({
  navigation,
  isUserLoggedIn,
  userPrefData,
  header,
  headerLink,
  isSiteSearchEnabled,
  headerLogo
}: NavigationProps) => {
  const { main, logIn, secondary } = navigation;
  const showMenuBreakpoint = useBreakpointValue<boolean>({
    base: true,
    md: false
  });

  const {
    isOpen,
    onToggle: onMenuToggle,
    onClose: onMenuClose
  } = useDisclosure();

  const isMenuOpen = isOpen && showMenuBreakpoint;

  // The parent that is currently clicked in to.
  const [expandedParent, setExpandedParent] = useState<PageNavigationData>(
    main?.find((x) => x.active) || null
  );

  // The secondary parent that is currently clicked in to.
  const [
    expandedSecondaryParent,
    setExpandedSecondaryParent
  ] = useState<PageNavigationData>(null);

  const childrenList = expandedParent?.children;

  const secondaryChildren = expandedSecondaryParent?.children;

  const handleParentClick = (item: PageNavigationData) => {
    if (isNotEmptyArray(item.children)) {
      setExpandedParent(item);
      useNavTracking({
        event: MegaNavEvent.OpenMenu,
        navMenuName: item.label,
        navMenuIndex: main?.indexOf(item)
      });
    } else {
      useNavTracking({
        event: MegaNavEvent.LinkClick,
        navMenuName: item.label,
        navMenuIndex: main?.indexOf(item),
        navMenuLinkText: item.label
      });
    }
  };

  const handleSecondaryParentClick = (item: PageNavigationData) => {
    if (isNotEmptyArray(item.children)) {
      setExpandedSecondaryParent(item);
      useNavTracking({
        event: MegaNavEvent.OpenMenu,
        navMenuName: item.label,
        navMenuIndex: secondary?.indexOf(item)
      });
    } else {
      useNavTracking({
        event: MegaNavEvent.LinkClick,
        navMenuName: item.label,
        navMenuIndex: secondary?.indexOf(item),
        navMenuLinkText: item.label
      });
    }
  };

  const resetExpandedParent = () => {
    setExpandedParent(null);
  };

  const resetExpandedSecondary = () => {
    setExpandedSecondaryParent(null);
  };

  const resetAllExpanded = () => {
    const activeParent = main.find((x) => x.active) || null;
    setExpandedParent(activeParent);
    setExpandedSecondaryParent(null);
  };

  const closeAll = () => {
    resetAllExpanded();
    onMenuClose();
  };

  const isNavigationEmpty = !navigation || !isNotEmptyArray(navigation.main);
  const navigationView = (
    <Box width="100%" display="block" bg={'black'} color={'white'}>
      <Grid
        w="full"
        width="100%"
        display="flex"
        position={'relative'}
        gridTemplateColumns="minmax(0,120px) minmax(0,170px)"
        gridColumnGap={2}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingX={6}
      >
        {/* LOGO */}
        <NzteLogo
          headerLogo={headerLogo}
          headerLink={headerLink}
          py={3}
          pr={10}
          logoWidth="200px"
          logoHeight="40px"
        />

        <Flex
          gridGap={2}
          display={'flex'}
          pt={1}
          width={'100%'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {isNotEmptyArray(logIn) && (
            <NavigationLoginButton
              loginSubMenu={logIn}
              mobile={true}
              isUserLoggedIn={isUserLoggedIn}
              userPrefData={userPrefData}
            />
          )}

          <Box
            as={'button'}
            border={0}
            color="white"
            _active={{ shadow: 'none' }}
            _focus={{ shadow: 'none' }}
            onClick={onMenuToggle}
            padding={0}
            flexShrink={0}
            display={isNavigationEmpty ? 'none' : 'flex'}
            justifyContent="flex-end"
            width={isNotEmptyArray(logIn) ? '20%' : '100%'}
          >
            <Box as={isMenuOpen ? CloseIcon : HamburgerIcon} h={8} w={8} />
          </Box>
        </Flex>
      </Grid>
    </Box>
  );

  return (
    <Box
      position={'sticky'}
      top={0}
      zIndex={2}
      w={'full'}
      display={{ base: 'block', md: 'none' }}
    >
      {navigation && navigationView}
      <HStack bg={'black'} color={'white'} px={0}>
        {isMenuOpen && (
          <Drawer
            onClose={() => {}}
            isOpen={isMenuOpen}
            size={'full'}
            placement="right"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerBody p={0}>
                {navigation && navigationView}
                <Box
                  display="flex"
                  justifyContent="center"
                  w={'full'}
                  width="100%"
                >
                  <SiteSearchEmbedded
                    m={0}
                    width={'full'}
                    display={isSiteSearchEnabled ? 'block' : 'none'}
                  />
                </Box>
                {isEmpty(expandedParent) &&
                  isEmpty(expandedSecondaryParent) &&
                  isMenuOpen && (
                    <MobileMainNavigationDrawer
                      mainItems={main}
                      logIn={logIn}
                      secondaryItems={secondary}
                      isOpen={isMenuOpen}
                      header={header}
                      headerLink={headerLink}
                      handleMainItemClick={handleParentClick}
                      handleSecondaryClick={handleSecondaryParentClick}
                      onClose={closeAll}
                      isUserLoggedIn={isUserLoggedIn}
                    />
                  )}
                {!!expandedParent && isMenuOpen && (
                  <MobileChildNavigationDrawer
                    type={NavigationItemType.PRIMARY_CHILDREN}
                    items={childrenList}
                    onBackButtonClick={() => {
                      resetExpandedParent();
                      useNavTracking({
                        event: MegaNavEvent.CloseMenu,
                        navMenuName: expandedParent?.label,
                        navMenuIndex: main?.indexOf(expandedParent)
                      });
                    }}
                    index={main?.indexOf(expandedParent)}
                    backButtonLabel={header}
                    header={expandedParent?.label || ''}
                    onClose={closeAll}
                    logIn={logIn}
                    isOpen={!!expandedParent && isMenuOpen}
                    isUserLoggedIn={isUserLoggedIn}
                  />
                )}
                {!!expandedSecondaryParent && isMenuOpen && (
                  <MobileChildNavigationDrawer
                    type={NavigationItemType.SECONDARY_DROPDOWN}
                    items={secondaryChildren}
                    onBackButtonClick={() => {
                      resetExpandedSecondary();
                      useNavTracking({
                        event: MegaNavEvent.CloseMenu,
                        navMenuName: expandedSecondaryParent?.label,
                        navMenuIndex: secondary.indexOf(expandedSecondaryParent)
                      });
                    }}
                    index={secondary.indexOf(expandedSecondaryParent)}
                    backButtonLabel={header}
                    header={expandedSecondaryParent?.label || ''}
                    onClose={closeAll}
                    isOpen={!!expandedSecondaryParent && isMenuOpen}
                    logIn={logIn}
                    isUserLoggedIn={isUserLoggedIn}
                  />
                )}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </HStack>
    </Box>
  );
};

export default NavigationMobile;
