/**
 * Creating a page named _error.js lets you override HTTP error messages
 */
import React from 'react';
import NextError from 'next/error';
import Page from '../components/Pages/Page';
import Page404 from '../components/Pages/Page404';
import { useServerSideRenderingDataContext } from '../components/ServerSideRenderingDataContext';
import { logError } from '../lib/appInsightsClient';

const ErrorPage = ({ statusCode, error = undefined }) => {
  let response;
  switch (statusCode) {
    case 200: // Also display a 404 if someone requests /_error explicitly
    case 404: {
      const dataContext = useServerSideRenderingDataContext();
      const { data, error, loading } = dataContext.PAGE_404;
      if (loading || error) return null;
      const page = data?.pageCollection?.items?.[0];
      if (page == null) return null;
      response = <Page404 {...page} />;
      break;
    }
    default:
      if (error) {
        logError('Unhandled error rendering component', { statusCode, error });
      }
      response = <NextError statusCode={statusCode} />;
  }

  return response;
};

ErrorPage.getInitialProps = ({ res, xhr }) => {
  const statusCode = res ? res.statusCode : xhr ? xhr.status : null;
  return { statusCode };
};

export default ErrorPage;
