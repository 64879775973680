import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import dynamic from 'next/dynamic';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { useServerSideRenderingDataContext } from './ServerSideRenderingDataContext';
import {
  BlockHeroWithSearchFragmentFragmentDoc,
  BlockBlogEntrySelectorFragmentFragmentDoc,
  BlockAccordionsFragmentFragmentDoc,
  BlockCarouselFragmentFragmentDoc,
  BlockCircleImageTextFragmentFragmentDoc,
  BlockCompanyProfilesFragmentFragmentDoc,
  BlockPersonProfilesFragmentFragmentDoc,
  BlockFaqsFragmentFragmentDoc,
  BlockFormsFragmentFragmentDoc,
  BlockFourColumnStatsFragmentFragmentDoc,
  BlockFullWidthImageTextOverlayFragmentFragmentDoc,
  BlockIconlogoTextFragmentFragmentDoc,
  BlockImageColumnsFragmentFragmentDoc,
  BlockJumpMenuFragmentFragmentDoc,
  BlockPlainCardsFragmentFragmentDoc,
  BlockQuotesFragmentFragmentDoc,
  BlockSectorCardsFragmentFragmentDoc,
  BlockRegionSelectorFragmentFragmentDoc,
  BlockSquareImageTextFragmentFragmentDoc,
  BlockTextEventFragmentFragmentDoc,
  BlockTextNewsFeedFragmentFragmentDoc,
  BlockVideoColumnsFragmentFragmentDoc,
  BlockTextContentFragmentFragmentDoc,
  BlockAnchorLinksFragmentFragmentDoc
} from '../data/generated/graphql';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { logError } from '../lib/appInsightsClient';

export const FRAGMENTS = {
  BlockBlogEntrySelectorFragment: BlockBlogEntrySelectorFragmentFragmentDoc,
  BlockCarouselFragment: BlockCarouselFragmentFragmentDoc,
  BlockCircleImageTextFragment: BlockCircleImageTextFragmentFragmentDoc,
  BlockSquareImageTextFragment: BlockSquareImageTextFragmentFragmentDoc,
  BlockCompanyProfilesFragment: BlockCompanyProfilesFragmentFragmentDoc,
  BlockFaqsFragment: BlockFaqsFragmentFragmentDoc,
  BlockFormsFragment: BlockFormsFragmentFragmentDoc,
  BlockFourColumnStatsFragment: BlockFourColumnStatsFragmentFragmentDoc,
  BlockFullWidthImageTextOverlayFragment: BlockFullWidthImageTextOverlayFragmentFragmentDoc,
  BlockIconlogoTextFragment: BlockIconlogoTextFragmentFragmentDoc,
  BlockImageColumnsFragment: BlockImageColumnsFragmentFragmentDoc,
  BlockJumpMenuFragment: BlockJumpMenuFragmentFragmentDoc,
  BlockPersonProfilesFragment: BlockPersonProfilesFragmentFragmentDoc,
  BlockPlainCardsFragment: BlockPlainCardsFragmentFragmentDoc,
  BlockQuotesFragment: BlockQuotesFragmentFragmentDoc,
  BlockRegionSelectorFragment: BlockRegionSelectorFragmentFragmentDoc,
  BlockSectorCardsFragment: BlockSectorCardsFragmentFragmentDoc,
  BlockTextContentFragment: BlockTextContentFragmentFragmentDoc,
  BlockTextEventFragment: BlockTextEventFragmentFragmentDoc,
  BlockTextNewsFeedFragment: BlockTextNewsFeedFragmentFragmentDoc,
  BlockVideoColumnsFragment: BlockVideoColumnsFragmentFragmentDoc,
  BlockHeroWithSearchFragment: BlockHeroWithSearchFragmentFragmentDoc,
  BlockAccordionsFragment: BlockAccordionsFragmentFragmentDoc,
  BlockAnchorLinksFragment: BlockAnchorLinksFragmentFragmentDoc
};

interface Props {
  id: string;
  typename: string;
  isAdvisor?: boolean;
  isManagedFund?: boolean;
  tag?: string;
}

export function getBlockQuery(typename) {
  const componentSelector =
    typename.charAt(0).toLowerCase() + typename.slice(1);
  const fragmentName = `${typename}Fragment`;
  const fragment = FRAGMENTS[fragmentName];
  return gql`
    query block($preview: Boolean! $id: String!) {
      block: ${componentSelector}(id: $id preview: $preview) {
        ...${fragmentName}
      }
    }
    ${fragment}
  `;
}

const Block = ({ id, typename, isAdvisor, isManagedFund, tag }: Props) => {
  const fragmentName = `${typename}Fragment`;
  const Component = dynamic(() => import(`../components/${typename}`));
  const fragment = FRAGMENTS[fragmentName];

  if (!Component || !fragment) {
    if (process.env.NODE_ENV !== 'test') {
      logError('Failed to load dynamic component for block', { typename });
    }
    return null;
  }

  const dataContext = useServerSideRenderingDataContext();
  const { data, loading, error } = dataContext.PAGE_BLOCKS.filter(
    (block) => block.id === id
  )[0] ?? { data: null, loading: false, error: true };
  const isPreview =
    process.env.NODE_ENV !== 'test' &&
    (((process.env.CTF_PREVIEW as unknown) as string) === 'true' ||
      process.env.CTF_PREVIEW === true);

  // if this is for an advisor then we need to truncate the body content
  if (isAdvisor && !isManagedFund && typename === 'BlockTextContent') {
    data?.block?.bodyContent?.json?.content?.splice(5, Infinity);
  }

  useEffect(() => {
    setTimeout(() => {
      forceCheck();
    }, 10);

    // Safari takes a while longer 8(
    setTimeout(() => {
      forceCheck();
    }, 300);
  }, []);

  if (loading || error || !data.block) return null;

  return (
    <LazyLoad once offset={500}>
      {isPreview ? (
        <Component {...useContentfulLiveUpdates(data.block)} id={tag} />
      ) : (
        <Component {...data.block} id={tag} />
      )}
    </LazyLoad>
  );
};

export default Block;
