import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Box, Button, Collapse } from '@chakra-ui/react';
import Container from './chakra-ui-components/Container/Container';
import { Icon, IconLibrary } from '@nzte/pallet-ui';
import RichText, { RichTextInterface } from './RichText';

/**
 * Component
 *
 */

const NotificationBanner = (props: { content: RichTextInterface }) => {
  const [visible, setVisible] = useState(false);

  // Close function
  const close = () => {
    Cookies.set(`notification-banner`, 'true');
    setVisible(false);
  };

  // On load show component if cookie exists
  useEffect(() => {
    let cookie = Cookies.get(`notification-banner`);
    if (!cookie) setVisible(true);
  }, []);

  return (
    <>
      <Collapse in={visible}>
        <Box
          backgroundColor="pallet.surface.subtle"
          position="relative"
          transition="color 0.3s ease-in"
          paddingY={4}
          _hover={{
            color: 'pallet.text.secondary.on-base-hovered'
          }}
        >
          <Container>
            <Button
              aria-label="Close"
              onClick={close}
              float="right"
              position="relative"
              backgroundColor="transparent"
              border="none"
              zIndex={10}
              width="30px"
              height="30px"
              padding={0}
              fontWeight="bold"
              fontSize="18.4px"
              lineHeight={1}
              outline="none !important"
              color="black"
              marginTop={-1}
              marginRight={-1}
              _hover={{
                opacity: 1
              }}
            >
              ×
            </Button>
            <Icon
              type="info"
              boxSize={5}
              as={IconLibrary.AiOutlineExclamationCircle}
              float="left"
            />
            <Box
              sx={{
                p: {
                  fontSize: '13.6px',
                  marginBottom: 0
                }
              }}
            >
              <RichText text={props.content} />
            </Box>
          </Container>
        </Box>
      </Collapse>
    </>
  );
};

export default NotificationBanner;
