/**
 * Component
 *
 */
interface Props {
  type: string;
  className?: string;
  variant?: string;
  hover?: boolean;
  color?: string;
  link?: {
    href: string;
    target?: string;
  };
  onClick?: () => void;
}

const Icon = (props: Props) => {
  const { type, color, hover, variant, onClick, link } = props;

  const TYPES = {
    share: Icon.ShareSVG,
    facebook: Icon.FacebookSVG,
    twitter: Icon.TwitterSVG,
    linkedin: Icon.LinkedinSVG,
    email: Icon.EmailSVG,
    link: Icon.LinkSVG,
    tick: Icon.TickSVG,
    arrowCircle: Icon.ArrowCircleSVG,
    arrow: Icon.ArrowSVG,
    envelope: Icon.EnvelopeSVG,
    home: Icon.HomeSVG,
    times: Icon.TimesSVG,
    lock: Icon.LockSVG,
    calendar: Icon.CalendarSVG,
    calendarSolid: Icon.CalendarSolidSVG,
    checkmark: Icon.CheckMark,
    binoculars: Icon.Binoculars,
    dollarbill: Icon.DollarBill,
    info: Icon.InfoSVG,
    eventLocation: Icon.EventLocationSVG,
    eventPrice: Icon.EventPriceSVG,
    eventRegister: Icon.EventRegisterSVG,
    eventScheduleDownload: Icon.EventScheduleDownloadSVG,
    checkboxDeselectedGrey: Icon.CheckboxDeselectedGrey,
    checkboxSelectedPrimary: Icon.CheckboxSelectedPrimary,
    previousRoundArrow: Icon.PreviousRoundArrow,
    nextRoundArrow: Icon.NextRoundArrow,
    playCircleOutlined: Icon.PlayCircleOutlined,
    pauseCircleOutlined: Icon.PauseCircleOutlined
  };
  const SVGIcon = TYPES[type];
  if (!SVGIcon) return;

  const opts = {
    className: `icon icon-${type}`,
    color,
    onClick
  };

  if (variant) opts.className += ` icon-${variant}`;

  if (hover === false) opts.className += ` icon-no-hover`;

  if (props.className) opts.className += ` ${props.className}`;

  if (link)
    return (
      <a {...link} {...opts}>
        <span className="icon-inner">
          <SVGIcon />
        </span>
      </a>
    );

  return (
    <span {...opts}>
      <span className="icon-inner">
        <SVGIcon />
      </span>
    </span>
  );
};

/**
 * Types
 *
 */
Icon.ShareSVG = () => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="#000000"
      transform="translate(-6.000000, -7.000000)"
      className="icon-svg-fill"
    >
      <path d="M16,7 C14.8945315,7 14,7.8945315 14,9 C14,9.138672 14.0195315,9.2753905 14.046875,9.40625 L9.5,11.6875 C9.1328125,11.267578 8.6015625,11 8,11 C6.8945315,11 6,11.8945315 6,13 C6,14.105469 6.8945315,15 8,15 C8.6015625,15 9.1328125,14.732422 9.5,14.3125 L14.046875,16.59375 C14.0195315,16.7246095 14,16.861328 14,17 C14,18.105469 14.8945315,19 16,19 C17.105469,19 18,18.105469 18,17 C18,15.8945315 17.105469,15 16,15 C15.3984375,15 14.8671875,15.267578 14.5,15.6875 L9.953125,13.40625 C9.980469,13.2753905 10,13.138672 10,13 C10,12.861328 9.980469,12.7246095 9.953125,12.59375 L14.5,10.3125 C14.8671875,10.732422 15.3984375,11 16,11 C17.105469,11 18,10.105469 18,9 C18,7.8945315 17.105469,7 16,7 Z" />
    </g>
  </svg>
);

Icon.FacebookSVG = () => (
  <svg
    width="8px"
    height="14px"
    viewBox="0 0 8 14"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1"
      transform="translate(-15.000000, -12.000000)"
    >
      <path
        d="M20.1931429,26 L20.1931429,19.614 L22.552,19.614 L22.9051429,17.125 L20.1931429,17.125 L20.1931429,15.536 C20.1931429,14.816 20.4137143,14.325 21.5497143,14.325 L23,14.324 L23,12.098 C22.7497143,12.068 21.8891429,12 20.8868571,12 C18.7965714,12 17.3657143,13.16 17.3657143,15.29 L17.3657143,17.125 L15,17.125 L15,19.614 L17.3657143,19.614 L17.3657143,26 L20.1931429,26 Z"
        fill="#000000"
        className="icon-svg-fill"
      />
    </g>
  </svg>
);

Icon.TwitterSVG = () => (
  <svg
    width="15px"
    height="12px"
    viewBox="0 0 15 12"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1"
      transform="translate(-12.000000, -13.000000)"
    >
      <path
        d="M16.644,25 C22.2174545,25 25.2654545,20.3832727 25.2654545,16.3785455 C25.2654545,16.2476364 25.2654545,16.1167273 25.2567273,15.9869091 C25.8490909,15.5581818 26.3607273,15.0269091 26.7676364,14.4192727 C26.2145455,14.6636364 25.6287273,14.8250909 25.0276364,14.896 C25.6603636,14.5174545 26.1338182,13.9218182 26.3596364,13.2192727 C25.7650909,13.5727273 25.1149091,13.8214545 24.4352727,13.9556364 C23.2887273,12.7349091 21.3687273,12.676 20.1490909,13.8236364 C19.3614545,14.5632727 19.0276364,15.6661818 19.272,16.7189091 C16.836,16.5967273 14.5658182,15.4458182 13.0276364,13.5530909 C12.2236364,14.9374545 12.6338182,16.708 13.9658182,17.5970909 C13.4836364,17.5829091 13.0123636,17.4530909 12.5901818,17.2185455 L12.5901818,17.2567273 C12.5912727,18.6989091 13.608,19.9414545 15.0218182,20.2272727 C14.5756364,20.3483636 14.1076364,20.3669091 13.6527273,20.2785455 C14.0498182,21.5134545 15.1876364,22.3589091 16.4836364,22.3829091 C15.4112727,23.2261818 14.0858182,23.6843636 12.7210909,23.6821818 C12.48,23.6821818 12.2389091,23.668 12,23.6385455 C13.3854545,24.5287273 14.9978182,25 16.644,24.9978182"
        fill="#000000"
        className="icon-svg-fill"
      />
    </g>
  </svg>
);

Icon.LinkedinSVG = () => (
  <svg
    width="13px"
    height="13px"
    viewBox="0 0 13 13"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1"
      transform="translate(-13.000000, -12.000000)"
    >
      <path
        d="M26,24.9752402 L23.3068133,24.9752402 L23.3068133,20.7604536 C23.3068133,19.754307 23.2888062,18.4622976 21.9067613,18.4622976 C20.5055839,18.4622976 20.2917496,19.5573543 20.2917496,20.6884252 L20.2917496,24.9752402 L17.6008138,24.9752402 L17.6008138,16.3081984 L20.1825816,16.3081984 L20.1825816,17.4932906 L20.2197212,17.4932906 C20.5798632,16.8112718 21.4577093,16.0932387 22.7677257,16.0932387 C25.4969267,16.0932387 26,17.8871959 26,20.2213661 L26,24.9752402 Z M14.5643667,15.1242317 C13.6989005,15.1242317 13,14.4242057 13,13.5621158 C13,12.700026 13.6989005,12 14.5643667,12 C15.4253311,12 16.1242317,12.700026 16.1242317,13.5621158 C16.1242317,14.4242057 15.4253311,15.1242317 14.5643667,15.1242317 L14.5643667,15.1242317 Z M13.2138343,24.9752402 L15.9103974,24.9752402 L15.9103974,16.3081984 L13.2138343,16.3081984 L13.2138343,24.9752402 Z"
        fill="#000000"
        className="icon-svg-fill"
      />
    </g>
  </svg>
);

Icon.LinkSVG = () => (
  <svg
    width="20px"
    height="10px"
    viewBox="0 0 20 10"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      transform="translate(-2.000000, -7.000000)"
    >
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M3.9,12 C3.9,10.29 5.29,8.9 7,8.9 L11,8.9 L11,7 L7,7 C4.24,7 2,9.24 2,12 C2,14.76 4.24,17 7,17 L11,17 L11,15.1 L7,15.1 C5.29,15.1 3.9,13.71 3.9,12 Z M8,13 L16,13 L16,11 L8,11 L8,13 Z M17,7 L13,7 L13,8.9 L17,8.9 C18.71,8.9 20.1,10.29 20.1,12 C20.1,13.71 18.71,15.1 17,15.1 L13,15.1 L13,17 L17,17 C19.76,17 22,14.76 22,12 C22,9.24 19.76,7 17,7 Z"
        fill="#000000"
        className="icon-svg-fill"
      />
    </g>
  </svg>
);

Icon.TickSVG = () => (
  <svg
    width="16px"
    height="12px"
    viewBox="0 0 16 12"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="#000000" className="icon-svg-fill">
      <path
        d="M0.988306684,6.35637151 L6.97081875,6.38475834 C7.51881274,6.38735855 7.96263548,6.83051873 7.96605437,7.37850823 L7.97096572,8.1657134 C7.97441134,8.7179874 7.52949803,9.16848717 6.97722403,9.17193279 C6.97356282,9.17195563 6.96990151,9.17195837 6.96624027,9.17194099 L0.983728207,9.14355417 C0.435734213,9.14095396 -0.0080885266,8.69779378 -0.0115074173,8.14980428 L-0.0164187677,7.36259911 C-0.0198643892,6.81032511 0.425048919,6.35982534 0.977322921,6.35637972 C0.980984133,6.35635688 0.984645442,6.35635414 0.988306684,6.35637151 Z"
        transform="translate(3.977273, 7.764156) rotate(45.000000) translate(-3.977273, -7.764156) "
      />
      <path
        d="M4.11207282,4.64068978 L16.0350582,4.57481693 C16.5873345,4.57176568 17.0375164,5.01700057 17.0405677,5.56927689 C17.040588,5.57296005 17.040588,5.57664329 17.0405677,5.58032645 L17.0362333,6.36485026 C17.0332059,6.91281642 16.5897395,7.35628278 16.0417733,7.35931022 L4.11878799,7.42518307 C3.56651167,7.42823432 3.11632972,6.98299943 3.11327847,6.43072311 C3.11325812,6.42703995 3.11325812,6.42335671 3.11327847,6.41967355 L3.11761286,5.63514974 C3.12064029,5.08718358 3.56410665,4.64371722 4.11207282,4.64068978 Z"
        transform="translate(10.076923, 6.000000) rotate(-45.000000) translate(-10.076923, -6.000000) "
      />
    </g>
  </svg>
);

Icon.ArrowSVG = () => (
  <svg
    width="8px"
    height="8px"
    viewBox="0 0 8 8"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1"
      transform="translate(-3.000000, -3.000000)"
      fill="#000000"
      className="icon-svg-fill"
    >
      <path d="M8.88520255,7.57142857 L3,7.57142857 L3,6.42857143 L8.88520255,6.42857143 L6.42857143,3.86458954 L7.16747507,3 L11,7 L7.16747507,11 L6.42857143,10.1354105 L8.88520255,7.57142857 Z" />
    </g>
  </svg>
);

Icon.EnvelopeSVG = () => (
  <svg
    width="22px"
    height="16px"
    viewBox="0 0 22 16"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="#000000"
      className="icon-svg-stroke"
      strokeWidth="1"
      fill="none"
      transform="translate(1.000000, 1.000000)"
    >
      <rect x="0" y="0" width="20" height="14" rx="1" />
      <polyline points="0 0 10 9 20 0" />
    </g>
  </svg>
);

Icon.HomeSVG = () => (
  <svg
    width="17px"
    height="14px"
    viewBox="0 0 17 14"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className="icon-svg-fill" fillRule="nonzero">
        <polygon points="6.8 14 6.8 9.05882353 10.2 9.05882353 10.2 14 14.45 14 14.45 7.41176471 17 7.41176471 8.5 0 0 7.41176471 2.55 7.41176471 2.55 14" />
      </g>
    </g>
  </svg>
);

Icon.TimesSVG = () => (
  <svg
    width="9px"
    height="10px"
    viewBox="0 0 9 10"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-10.000000, -8.000000)" strokeWidth="2">
      <g
        className="icon-svg-stroke"
        transform="translate(14.500000, 13.000000) rotate(45.000000) translate(-14.500000, -13.000000) translate(9.000000, 8.000000)"
      >
        <path d="M5.5,0 L5.5,10" />
        <path
          d="M5.5,0 L5.5,10"
          transform="translate(5.500000, 5.000000) rotate(-90.000000) translate(-5.500000, -5.000000) "
        />
      </g>
    </g>
  </svg>
);

Icon.LockSVG = () => (
  <svg
    height="24px"
    width="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g className="icon-svg-fill">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
    </g>
  </svg>
);
Icon.EmailSVG = () => (
  <svg
    width="20px"
    height="16px"
    viewBox="0 0 20 16"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="#000000"
      fillRule="nonzero"
      transform="translate(-2.000000, -4.000000)"
      className="icon-svg-fill"
    >
      <path d="M22,6 C22,4.9 21.1,4 20,4 L4,4 C2.9,4 2,4.9 2,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 Z M20,6 L12,10.99 L4,6 L20,6 Z M20,18 L4,18 L4,8 L12,13 L20,8 L20,18 Z" />
    </g>
  </svg>
);

Icon.CalendarSolidSVG = () => (
  <svg
    width="12px"
    height="13px"
    viewBox="0 0 12 13"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1.000000, 0.000000)">
        <polygon points="0 0 14 0 14 14 0 14" />
        <path
          d="M11.0833333,1.75 L10.5,1.75 L10.5,0.583333333 L9.33333333,0.583333333 L9.33333333,1.75 L4.66666667,1.75 L4.66666667,0.583333333 L3.5,0.583333333 L3.5,1.75 L2.91666667,1.75 C2.26916667,1.75 1.75583333,2.275 1.75583333,2.91666667 L1.75,11.0833333 C1.75,11.725 2.26916667,12.25 2.91666667,12.25 L11.0833333,12.25 C11.725,12.25 12.25,11.725 12.25,11.0833333 L12.25,2.91666667 C12.25,2.275 11.725,1.75 11.0833333,1.75 Z M11.0833333,4.08333333 L2.91666667,4.08333333 L2.91666667,2.91666667 L11.0833333,2.91666667 L11.0833333,4.08333333 Z"
          fill="#000000"
          className="icon-svg-fill"
        />
      </g>
    </g>
  </svg>
);

Icon.CalendarSVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="solid" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(1.000, 0)">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M19,3 L18,3 L18,1 L16,1 L16,3 L8,3 L8,1 L6,1 L6,3 L5,3 C3.89,3 3.01,3.9 3.01,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,9 L19,9 L19,19 Z M19,7 L5,7 L5,5 L19,5 L19,7 Z M17,12 L12,12 L12,17 L17,17 L17,12 Z"
          fill="#000000"
          className="icon-svg-fill"
        />
      </g>
    </g>
  </svg>
);

Icon.EventLocationSVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M12,2 C8.13,2 5,5.13 5,9 C5,14.25 12,22 12,22 C12,22 19,14.25 19,9 C19,5.13 15.87,2 12,2 Z M7,9 C7,6.24 9.24,4 12,4 C14.76,4 17,6.24 17,9 C17,11.88 14.12,16.19 12,18.88 C9.92,16.21 7,11.85 7,9 Z"
          fill="#000000"
          className="icon-svg-fill"
          fillRule="nonzero"
        />
        <circle
          fill="#000000"
          className="icon-svg-fill"
          fillRule="nonzero"
          cx="12"
          cy="9"
          r="2.5"
        />
      </g>
    </g>
  </svg>
);

Icon.EventPriceSVG = () => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1.000000, -1.000000)">
        <polygon points="0 0 20 0 20 20 0 20" />
        <path
          d="M17.8416667,9.65 L10.3416667,2.15 C10.0416667,1.85 9.625,1.66666667 9.16666667,1.66666667 L3.33333333,1.66666667 C2.41666667,1.66666667 1.66666667,2.41666667 1.66666667,3.33333333 L1.66666667,9.16666667 C1.66666667,9.625 1.85,10.0416667 2.15833333,10.35 L9.65833333,17.85 C9.95833333,18.15 10.375,18.3333333 10.8333333,18.3333333 C11.2916667,18.3333333 11.7083333,18.15 12.0083333,17.8416667 L17.8416667,12.0083333 C18.15,11.7083333 18.3333333,11.2916667 18.3333333,10.8333333 C18.3333333,10.375 18.1416667,9.95 17.8416667,9.65 Z M10.8333333,16.675 L3.33333333,9.16666667 L3.33333333,3.33333333 L9.16666667,3.33333333 L9.16666667,3.325 L16.6666667,10.825 L10.8333333,16.675 L10.8333333,16.675 Z"
          fill="#000000"
          className="icon-svg-fill"
          fillRule="nonzero"
        />
        <circle
          fill="#000000"
          className="icon-svg-fill"
          fillRule="nonzero"
          cx="5.41666667"
          cy="5.41666667"
          r="1.25"
        />
      </g>
    </g>
  </svg>
);

Icon.InfoSVG = () => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1.000000, -1.000000)">
        <polygon points="0 0 20 0 20 20 0 20" />
        <path
          d="M9.16666667,5.83333333 L10.8333333,5.83333333 L10.8333333,7.5 L9.16666667,7.5 L9.16666667,5.83333333 Z M9.16666667,9.16666667 L10.8333333,9.16666667 L10.8333333,14.1666667 L9.16666667,14.1666667 L9.16666667,9.16666667 Z M10,1.66666667 C5.4,1.66666667 1.66666667,5.4 1.66666667,10 C1.66666667,14.6 5.4,18.3333333 10,18.3333333 C14.6,18.3333333 18.3333333,14.6 18.3333333,10 C18.3333333,5.4 14.6,1.66666667 10,1.66666667 Z M10,16.6666667 C6.325,16.6666667 3.33333333,13.675 3.33333333,10 C3.33333333,6.325 6.325,3.33333333 10,3.33333333 C13.675,3.33333333 16.6666667,6.325 16.6666667,10 C16.6666667,13.675 13.675,16.6666667 10,16.6666667 Z"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

Icon.EventRegisterSVG = () => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1.000000, -1.000000)">
        <polygon points="0 0 20 0 20 20 0 20" />
        <path
          d="M10.4166667,6.66666667 L9.16666667,6.66666667 L9.16666667,11.6666667 L13.125,14.0416667 L13.75,13.0166667 L10.4166667,11.0416667 L10.4166667,6.66666667 Z M14.4475,1.50833333 L18.2866667,4.7125 L17.22,5.99166667 L13.3783333,2.78916667 L14.4475,1.50833333 Z M5.5525,1.50833333 L6.62083333,2.78833333 L2.78083333,5.99166667 L1.71416667,4.71166667 L5.5525,1.50833333 Z M10,3.33333333 C5.85833333,3.33333333 2.5,6.69166667 2.5,10.8333333 C2.5,14.975 5.85833333,18.3333333 10,18.3333333 C14.1416667,18.3333333 17.5,14.975 17.5,10.8333333 C17.5,6.69166667 14.1416667,3.33333333 10,3.33333333 Z M10,16.6666667 C6.78333333,16.6666667 4.16666667,14.05 4.16666667,10.8333333 C4.16666667,7.61666667 6.78333333,5 10,5 C13.2166667,5 15.8333333,7.61666667 15.8333333,10.8333333 C15.8333333,14.05 13.2166667,16.6666667 10,16.6666667 Z"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

Icon.EventScheduleDownloadSVG = () => (
  <svg
    width="14px"
    height="13px"
    viewBox="0 0 14 13"
    version="1.1"
    xmlBase="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-3.000000, -3.000000)">
        <g transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000) ">
          <polygon opacity="0.87" points="20 0 20 20 0 20 0 0" />
          <g transform="translate(4.000000, 3.000000)" fill="#000000">
            <path
              d="M8.34166667,10.825 L4.51666667,7 L8.34166667,3.175 L7.16666667,2 L2.16666667,7 L7.16666667,12 L8.34166667,10.825 Z M2.84217094e-14,0 L1.66666667,0 L1.66666667,14 L2.84217094e-14,14 L2.84217094e-14,0 Z"
              fillRule="nonzero"
            />
            <rect
              transform="translate(8.000000, 7.000000) rotate(90.000000) translate(-8.000000, -7.000000) "
              x="7"
              y="2"
              width="2"
              height="10"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Icon.ArrowCircleSVG = () => (
  <svg width="43px" height="42px" viewBox="0 0 43 42" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Lock" transform="translate(-280.000000, -3592.000000)">
        <g id="SMS" transform="translate(153.000000, 3207.000000)">
          <g id="Group">
            <g id="Login" transform="translate(0.000000, 369.000000)">
              <g id="Btn">
                <g
                  id="Oval-302-+-Shape"
                  transform="translate(128.000000, 17.000000)"
                >
                  <circle
                    id="Oval-302"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    cx="20.5"
                    cy="20"
                    r="20"
                  />
                  <path
                    d="M17.8,15.4 L19.2,14 L25.2,20 L19.2,26 L17.8,24.6 L22.4,20 L17.8,15.4 Z"
                    id="Shape"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Icon.CheckMark = () => (
  <svg
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.997505 4.8158L2.63186 7.13525C2.75764 7.32314 2.96645 7.43867 3.19246 7.44542C3.41846 7.45218 3.6338 7.34931 3.77057 7.16927L9.00251 0.549805"
      stroke="#5E6871"
      stroke-width="1.5"
    />
  </svg>
);

Icon.DollarBill = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <defs>
      <style>{`
      .dollar-cls-1{stroke:#fff;stroke-width:0.036px;}
    `}</style>
    </defs>
    <g>
      <g>
        <path
          className="dollar-cls-1"
          d="M51.69,44.71c-8.38-2.18-11.08-4.43-11.08-7.94,0-4,3.73-6.83,10-6.83,6.57,0,9,3.14,9.23,7.76H68c-.26-6.35-4.14-12.19-11.85-14.07V15.54H45v8c-7.16,1.55-12.92,6.2-12.92,13.33,0,8.53,7.05,12.77,17.35,15.25C58.7,54.31,60.55,57.56,60.55,61c0,2.55-1.81,6.61-10,6.61-7.61,0-10.6-3.4-11-7.75H31.45C31.9,67.93,38,72.47,45,74v8H56.12V74.06C63.32,72.7,69,68.52,69,61,69,50.47,60.07,46.89,51.69,44.71Z"
        />
        <path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,94.74A44.74,44.74,0,1,1,94.74,50,44.79,44.79,0,0,1,50,94.74Z" />
      </g>
    </g>
  </svg>
);

Icon.Binoculars = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 66.69">
    <defs>
      <style>{`
        .binoculars-cls-1{
          fill:#231f20;fill-rule:evenodd;
        }
      `}</style>
    </defs>
    <g>
      <g>
        <path
          className="binoculars-cls-1"
          d="M25.09,6.5a11.14,11.14,0,0,1,5.59-1.88c4.83,0,11.2,4.13,11.2,7.56V28.87A23.41,23.41,0,0,0,14.74,21.4l8-13.16A17.22,17.22,0,0,1,25.09,6.5ZM46.5,22.71v6.34h7V22.71Zm.21,19.48c0,.31,0,.62,0,.94A23.37,23.37,0,1,1,2.1,33.43h0l.1-.15A23.74,23.74,0,0,1,4.76,29L18.92,5.56l.39-.48a20.38,20.38,0,0,1,3.36-2.51,15.37,15.37,0,0,1,8-2.57C37.75,0,46.5,5.68,46.5,12.18v3.39h7v-3.3C53.46,5.77,62.21.09,69.28.09a15.37,15.37,0,0,1,8,2.57,21.55,21.55,0,0,1,3.37,2.52l.38.48,14.3,23.65a23.47,23.47,0,0,1,2.41,4l.13.21h0a23.37,23.37,0,1,1-44.59,9.79c0-.38,0-.76,0-1.13Zm-4.83-2v-.06A18.75,18.75,0,0,0,8.58,31.6L6.27,35.43a18.75,18.75,0,1,0,35.84,7.7,19.69,19.69,0,0,0-.22-2.9Zm49.63-8.32,2.16,3.57a18.77,18.77,0,1,1-2.16-3.57Zm-33.43-2.8V12.27c0-3.43,6.38-7.56,11.2-7.56A11.06,11.06,0,0,1,74.87,6.6a17.52,17.52,0,0,1,2.39,1.73l8,13.28a23.36,23.36,0,0,0-27.2,7.5ZM23.28,59.53A16.32,16.32,0,1,0,7,43.21,16.32,16.32,0,0,0,23.28,59.53Zm0-4.62A11.7,11.7,0,1,1,35,43.21,11.69,11.69,0,0,1,23.28,54.91Zm69.58-11.5A16.32,16.32,0,1,1,76.55,27.09,16.31,16.31,0,0,1,92.86,43.41Zm-28,0a11.7,11.7,0,1,0,11.7-11.7A11.7,11.7,0,0,0,64.85,43.41ZM46.5,33.34h7V29.05h-7Z"
        />
      </g>
    </g>
  </svg>
);

Icon.CheckboxDeselectedGrey = ({ color = '#CBCACA' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7z"
        id="a"
      />
    </defs>
    <path
      stroke={color}
      d="M8 1.5A6.503 6.503 0 001.5 8c0 3.588 2.912 6.5 6.5 6.5s6.5-2.912 6.5-6.5S11.588 1.5 8 1.5z"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

Icon.CheckboxSelectedPrimary = ({ color = '#242429' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.25C5.17 1.25 1.25 5.17 1.25 10C1.25 14.83 5.17 18.75 10 18.75C14.83 18.75 18.75 14.83 18.75 10C18.75 5.17 14.83 1.25 10 1.25ZM14.0162 6.1325L8.24999 11.8987L5.98374 9.64125L4.74999 10.875L8.24999 14.375L15.25 7.375L14.0162 6.1325Z"
      fill={color}
    />
  </svg>
);

Icon.PreviousRoundArrow = ({ color = '#797979' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.148,17.521,10"
      fill={color}
      color="#fffff"
    />
  </svg>
);

Icon.NextRoundArrow = ({ color = '#797979' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.522,10.4l-3.559,3.562c-0.172,0.173-0.451,0.176-0.625,0c-0.173-0.173-0.173-0.451,0-0.624l3.248-3.25L8.161,6.662c-0.173-0.173-0.173-0.452,0-0.624c0.172-0.175,0.451-0.175,0.624,0l3.738,3.736C12.695,9.947,12.695,10.228,12.522,10.4 M18.406,10c0,4.644-3.764,8.406-8.406,8.406c-4.644,0-8.406-3.763-8.406-8.406S5.356,1.594,10,1.594C14.643,1.594,18.406,5.356,18.406,10M17.521,10c0-4.148-3.374-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.147,17.521,10"
      fill={color}
      color="#fffff"
    />
  </svg>
);

Icon.PlayCircleOutlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
);

Icon.PauseCircleOutlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z" />
  </svg>
);

export default Icon;
