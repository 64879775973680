import {
  BoxProps,
  Flex,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal
} from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';
import React from 'react';
import { PageNavigationData } from '../../../data/navigation.data';
import {
  getHrefFromPageNavigationData,
  isPageNavigationExternalLink,
  shouldOpenPageNavInNewTab
} from '../../../lib/utils/link';
import TextLink from './TextLink';
import AnimatedChevronDown from '../../chakra-ui-components/Icon/AnimatedChevronDown/AnimatedChevronDown';
import { MegaNavEvent, useNavTracking } from '../../../helpers/useGaTracking';

type Props = {
  navItem?: PageNavigationData;
  mobile?: boolean;
} & BoxProps;

const NavigationPopOver = (props: Props) => {
  const { navItem, mobile, key, ...passThroughProps } = props;

  // Disabling autofocus because of this issue with chakra - https://github.com/chakra-ui/chakra-ui/issues/3461
  return (
    <Popover
      placement={'bottom-end'}
      autoFocus={false}
      id={`nav-${navItem.id}`}
    >
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Box
              as={'button'}
              border={0}
              color={'white'}
              _hover={{ shadow: 'none', color: 'neutral.dark' }}
              transition={'color 0.3s ease'}
              {...passThroughProps}
              onClick={() => {
                useNavTracking({
                  event: !isOpen
                    ? MegaNavEvent.OpenMenu
                    : MegaNavEvent.CloseMenu,
                  navMenuName: props.navItem.label,
                  navMenuIndex: navItem.key
                });
              }}
            >
              <Flex alignItems={'center'} fontWeight={'bold'}>
                {props.navItem.label}
                <AnimatedChevronDown
                  width={2}
                  height={2}
                  marginLeft={2}
                  isOpen={isOpen}
                />
              </Flex>
              <Box
                position={'absolute'}
                right={{ base: 'calc(50% - 0.25rem)', md: 10 }}
                bottom={'-0.8rem'}
                h={2}
                w={2}
                background={'#fff'}
                transform={'rotate(45deg)'}
                display={isOpen ? 'block' : 'none'}
              />
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              bg="white"
              border={0}
              _focus={{ border: 0, outline: 'none' }}
              zIndex={'dropdown'}
              padding={0}
              overflow={'hidden'}
              shadow="md"
            >
              <PopoverArrow />
              <PopoverBody padding={0}>
                {navItem?.children.map((item, index) => (
                  <React.Fragment key={index}>
                    <TextLink
                      paddingY={4}
                      paddingLeft={4}
                      paddingRight={{ base: 12, md: 10 }}
                      _hover={{
                        bg: 'neutral.light',
                        textDecoration: 'none'
                      }}
                      bgColor={item.active ? 'neutral.light' : 'none'}
                      label={item.label}
                      href={getHrefFromPageNavigationData(item)}
                      isExternal={isPageNavigationExternalLink(item)}
                      width={'100%'}
                      newTab={shouldOpenPageNavInNewTab(item)}
                      onClick={() => {
                        useNavTracking({
                          event: MegaNavEvent.LinkClick,
                          navMenuName: props.navItem.label,
                          navMenuIndex: navItem.key,
                          navMenuLinkText: item.label
                        });
                      }}
                    />

                    {index !== navItem?.children.length - 1 && (
                      <Box
                        borderBottomWidth={'1px'}
                        borderBottomColor={'neutral.dark'}
                      />
                    )}
                  </React.Fragment>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default NavigationPopOver;
