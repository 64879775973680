import React from 'react';

export const generateCanonicalUrl = ({ asPath }: { asPath: string }) => {
  const asPathLen = asPath.length;
  const endOfPathIndex = Math.min.apply(Math, [
    asPath.indexOf('?') > 0 ? asPath.indexOf('?') : asPathLen,
    asPath.indexOf('#') > 0 ? asPath.indexOf('#') : asPathLen
  ]);
  return process.env.SITE_URL + asPath.substring(0, endOfPathIndex);
};

export const generateCanonicalTag = ({ asPath }: { asPath: string }) => {
  const canonicalURL = generateCanonicalUrl({ asPath });
  if (canonicalURL) {
    return React.createElement('link', {
      rel: 'canonical',
      href: canonicalURL
    });
  }
};
