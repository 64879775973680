import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import PlayButton from '../../../assets/svgs/play-button.svg';

export const VideoIframeButton = ({
  viewTime,
  ...props
}: {
  viewTime?: string;
} & BoxProps) => {
  return (
    <Flex
      border="1px solid white"
      margin="auto"
      color="white"
      fontWeight="900"
      borderRadius="100px"
      px={{ base: 1, lg: pxToRem(6) }}
      py={{ base: pxToRem(3), lg: 1 }}
      transition="all .25s ease"
      height={{ base: pxToRem(46), lg: pxToRem(54) }}
      _hover={{
        transform: 'scale(1.05)',
        opacity: 0.9
      }}
      {...props}
    >
      <Box as={PlayButton} boxSize={{ base: 9, lg: pxToRem(42) }} m="auto" />
      {viewTime && (
        <Box as="span" m="auto" ml="10px" mr="11px">
          {viewTime}
        </Box>
      )}
    </Flex>
  );
};
