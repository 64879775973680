import Layout from '../Layout';
import Block from '../Block';
import { ContentfulPage404FragmentFragment } from '../../data/generated/graphql';

/**
 * Contentful interface
 *
 */
export interface PageInterface extends ContentfulPage404FragmentFragment {}

/**
 * Component
 *
 */
const Page404 = (props: PageInterface) => {
  const { title, metaDescription, shareImage, section, sector } = props;

  const sectorClass =
    sector && sector.sys.id ? `sector-${sector.sys.id}`.toLowerCase() : '';

  const blocks = props.blocksCollection.items;

  return (
    <Layout
      title={title}
      metaDescription={metaDescription}
      ogImage={shareImage && shareImage.url ? shareImage.url : null}
      section={section}
      className={props.className}
    >
      <div className={`content-blocks ${sectorClass}`}>
        {blocks.map((block, key) => (
          <Block id={block.sys.id} typename={block.__typename} key={key} />
        ))}
      </div>
    </Layout>
  );
};

export default Page404;
