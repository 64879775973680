import { Box } from '@chakra-ui/react';
import Icon from '../Icon';
import { convertSecondsToMinutes } from '../../helpers/convertSecondsToMinutes';
import Grid, { GridItem } from '../chakra-ui-components/Grid/Grid';

export interface BaseChapterProps {
  label: string;
  marker: number;
}

export interface ChapterProps extends BaseChapterProps {
  isCurrent: boolean;
  isPlaying: boolean;
  onClick: () => void;
}

export const LabelStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const Chapter = ({
  label,
  marker,
  isCurrent,
  isPlaying,
  onClick
}: ChapterProps) => {
  const canDisplayPauseButton = isCurrent && isPlaying;
  return (
    <Grid
      fontSize="text"
      py={3}
      px={4}
      w="100%"
      gridGap={2}
      bg={isCurrent && 'grey.04'}
      onClick={onClick}
    >
      {canDisplayPauseButton ? (
        <GridItem column="span 1" display="flex" alignItems="center">
          <Icon type="pauseCircleOutlined" className="pause-circle-outlined" />
        </GridItem>
      ) : (
        <GridItem column="span 1" display="flex">
          <Icon type="playCircleOutlined" className="play-circle-outlined" />
        </GridItem>
      )}
      <GridItem column="span 8" alignItems="center">
        <Box whiteSpace="nowrap" overflow="ellipses" {...LabelStyle}>
          {label}
        </Box>
      </GridItem>
      <GridItem column="span 3" display="flex" alignItems="center">
        <Box marginLeft="auto">{convertSecondsToMinutes(marker)}</Box>
      </GridItem>
    </Grid>
  );
};
