import React, { useContext } from 'react';

/**
 * Context
 *
 */
export const SiteContext = React.createContext(null);

/**
 * Use context consumer hook
 *
 */
export const useSiteContext = () => useContext(SiteContext);
