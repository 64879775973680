import React from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import ArrowLineRight from '../../../assets/svgs/arrow-line-right.svg';

type MobileBoxLinkProps = {
  label: string;
  href: string;
  isExternal: boolean;
  isActive: boolean;
  newTab: boolean;
} & BoxProps;

const MobileTextLink = ({
  label,
  href,
  isActive = false,
  isExternal,
  newTab = false,
  ...passThroughProps
}: MobileBoxLinkProps) => {
  return (
    <Box
      _hover={{ color: 'neutral.dark', textDecoration: 'none' }}
      fontSize={pxToRem(15)}
      display="flex"
      alignItems="center"
      as="a"
      href={href}
      className="primary-parent"
      color={isActive ? 'neutral.dark' : 'white'}
      textDecoration="none"
      rel={isExternal ? 'noopener noreferrer' : undefined}
      target={newTab ? '_blank' : '_self'}
      {...passThroughProps}
    >
      <Text padding={0} marginBottom={0} fontWeight="bold">
        {label}{' '}
      </Text>
      <Box
        display={isExternal ? 'svg' : 'none'}
        as={ArrowLineRight}
        w={pxToRem(10)}
        h={pxToRem(10)}
        marginLeft={2}
        sx={{
          '.primary-parent:hover &': {
            transform: 'rotate(45deg)',
            transition: 'all 0.5s ease'
          }
        }}
      />
    </Box>
  );
};

export default MobileTextLink;
