import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

// wrapper for lodash throttle to use async functions. only resolves if the throttle is called.
// https://github.com/lodash/lodash/issues/4815

export function asyncThrottle<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number
) {
  const throttled = throttle((resolve, reject, args: Parameters<F>) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  const returnFunction = (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      throttled(resolve, reject, args);
    }) as ReturnType<F>;

  return {
    func: returnFunction,
    throttleInstance: throttled
  };
}

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number
) {
  const debounced = debounce((resolve, reject, args: Parameters<F>) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  const returnFunction = (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<F>;

  return {
    func: returnFunction,
    debounceInstance: debounced
  };
}
