import { StyleProps, useToken } from '@chakra-ui/react';

export type VariantStylesType =
  | 'full'
  | 'full-white'
  | 'full-dark'
  | 'full-box-dark'
  | 'full-orange'
  | 'outlined'
  | 'outlined-dark'
  | 'outlined-red'
  | 'outlined-white'
  | 'outlined-orange'
  | 'normal';

export const useButtonVariantStyles = ({
  variant,
  brandColor = 'black',
  brandColorHover = 'grey.13',
  brandColorHoverLight = 'grey.13',
  brandColorHoverMedium = 'grey.13',
  brandColorHoverDark = 'grey.13',
  withChildren = false
}: {
  variant: VariantStylesType;
  brandColor: string;
  brandColorHover?: string;
  brandColorHoverLight?: string;
  brandColorHoverMedium?: string;
  brandColorHoverDark?: string;
  withChildren?: boolean;
}): Partial<StyleProps> => {
  if (variant.includes('-dark')) {
    brandColor = 'black';
    brandColorHoverLight = 'grey.13';
  } else if (variant.includes('-white')) {
    brandColor = variant.includes('outlined') ? 'black' : 'white';
    brandColorHoverLight = 'grey.10';
    brandColorHoverMedium = 'grey.11';
    brandColorHoverDark = 'grey.12';
  } else if (variant === 'outlined-red') {
    brandColor = 'alert';
    brandColorHoverLight = 'orange.60';
    brandColorHoverMedium = 'orange.70';
    brandColorHoverDark = 'orange.80';
  } else if (variant.includes('-orange')) {
    brandColor = 'orange.70';
    brandColorHoverLight = 'orange.60';
    brandColorHoverMedium = 'orange.800';
    brandColorHoverDark = 'orange.900';
  }
  // Resolve the brand color prop to the value in theme.
  const [
    themeBrandColor,
    themeBrandColorHover,
    themeBrandColorHoverLight,
    themeBrandColorHoverMedium,
    themeBrandColorHoverDark
  ] = useToken('colors', [
    brandColor,
    brandColorHover,
    brandColorHoverLight,
    brandColorHoverMedium,
    brandColorHoverDark
  ]);

  const backgroundGradient = variant.includes('-dark')
    ? `linear-gradient(200.03deg, ${themeBrandColorHoverLight} 10.59%, ${themeBrandColor} 97.85%)`
    : `linear-gradient(200.03deg, ${themeBrandColorHoverLight} 9.22%, ${themeBrandColorHoverMedium} 48.54%, ${themeBrandColorHoverDark} 96.83%)`;

  return {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: 1.2, // Lets button text wrap properly
    verticalAlign: 'middle',
    minHeight: 9, // Need to be custom
    rounded: variant.includes('box') ? '20px' : 'full',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    minWidth: 32,

    ...(variant.includes('full') && {
      background: brandColor,
      color: 'white !important',
      transition: 'all 0.3s ease-in-out',
      position: 'relative',
      _before: {
        borderRadius: '21px',
        background: backgroundGradient,
        content: "''",
        display: 'block',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        width: '100%',
        zIndex: -100,
        transition: 'opacity 0.3s ease-in-out'
      },

      _focus: {
        background: backgroundGradient
      },

      _disabled: {
        pointerEvents: 'none',
        color: 'white !important',
        opacity: 0.3
      },

      _hover: {
        backgroundImage: backgroundGradient,
        backgroundPosition: '100% 0',
        _before: {
          opacity: 1
        }
      }
    }),

    ...(variant === 'full-white' && {
      background: 'white',
      color: 'black !important',
      _before: {
        borderRadius: '21px',
        background: backgroundGradient,
        content: "''",
        display: 'block',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        width: '100%',
        zIndex: -100,
        transition: 'opacity 0.3s ease-in-out'
      },
      _focus: {
        background: backgroundGradient
      },

      _disabled: {
        pointerEvents: 'none',
        color: 'black !important',
        opacity: 0.5
      },

      _hover: {
        backgroundImage: backgroundGradient,
        backgroundPosition: '100% 0',
        _before: {
          opacity: 1
        }
      }
    }),

    ...(variant === 'normal' && {
      background: 'white',
      color: 'black !important',
      _before: {
        borderRadius: '21px',
        content: "''",
        display: 'block',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        width: '100%',
        zIndex: -100,
        transition: 'opacity 0.3s ease-in-out'
      },
      _focus: {
        color: 'grey !important'
      },

      _disabled: {
        pointerEvents: 'none',
        color: 'black !important',
        opacity: 0.5
      },

      _hover: {
        color: 'grey !important',
        _before: {
          opacity: 1
        }
      }
    }),

    ...(variant.includes('outlined') && {
      position: 'relative',
      zIndex: '1',
      lineHeight: '16px',
      ...(!withChildren && {
        background: variant === 'outlined-white' ? 'white' : themeBrandColor,
        border: 'none',
        outline: 'none',
        _after: {
          content: 'attr(label)',
          color:
            variant === 'outlined-white'
              ? 'white'
              : variant === 'outlined-orange'
              ? 'black'
              : themeBrandColor
        },
        _before: {
          content: "''",
          position: 'absolute',
          left: '1px',
          right: '1px',
          top: '1px',
          bottom: '1px',
          borderRadius: '21px',
          backgroundColor:
            variant === 'outlined-white' ? themeBrandColor : 'white',
          zIndex: '-1',
          transition: '200ms'
        },
        _hover: {
          backgroundImage: backgroundGradient,
          backgroundPosition: '100% 0',
          border: 'none',
          outline: 'none',
          position: 'relative',
          zIndex: '1',
          _after: {
            content: 'attr(label)',
            background: backgroundGradient,
            backgroundClip: 'text',
            color: 'transparent',
            transition: '200ms',
            lineHeight: '16px'
          }
        },
        _focus: {
          backgroundImage: backgroundGradient,
          border: 'none',
          outline: 'none',
          position: 'relative',
          zIndex: '1',
          _after: {
            content: 'attr(label)',
            background: backgroundGradient,
            backgroundClip: 'text',
            color: 'transparent',
            transition: '200ms',
            lineHeight: '16px'
          }
        }
      }),

      ...(withChildren && {
        background: 'transparent',
        color:
          variant === 'outlined-white'
            ? 'white'
            : variant === 'outlined-orange'
            ? 'black'
            : themeBrandColor,
        border: `1px solid ${
          variant === 'outlined-white' ? 'white' : themeBrandColor
        }`,
        borderRadius: '21px',
        transition: 'all 0.3s ease-in-out',
        _hover: {
          color: themeBrandColorHover,
          borderColor: themeBrandColorHover
        },
        _focus: {
          color: themeBrandColorHover,
          borderColor: themeBrandColorHover
        }
      }),

      _disabled: {
        opacity: variant === 'outlined-white' ? 0.5 : 0.3,
        pointerEvents: 'none'
      }
    })
  };
};
