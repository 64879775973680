import { axiosInstance } from './axios-instance';

export const fetchSuggestions = async (
  term: string
): Promise<Array<string> | undefined> => {
  const suggestionsURL = `/api/search/autocomplete`;
  const response = await axiosInstance.post(suggestionsURL, {
    term: term
  });
  return response?.data?.data;
};
