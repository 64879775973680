import React, { useRef } from 'react';
import {
  BoxProps,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';
import Button from '../../chakra-ui-components/Button/Button';
import UserSVG from '../../../assets/svgs/user.svg';
import { PageNavigationData } from '../../../data/navigation.data';
import { pxToRem } from '../../../styles/chakra/theme-utils';
import { UserData } from '../../../models/my-account';
import LoggedOutPopoverContent from './LoggedOutPopoverContent';
import LoggedInPopoverContent from './LoggedInPopoverContent';
import AnimatedChevronDown from '../../chakra-ui-components/Icon/AnimatedChevronDown/AnimatedChevronDown';
import { MegaNavEvent, useNavTracking } from '../../../helpers/useGaTracking';

type Props = {
  loginSubMenu?: Array<PageNavigationData>;
  mobile?: boolean;
  isUserLoggedIn: boolean;
  userPrefData?: UserData;
} & BoxProps;

const NavigationLoginButton = (props: Props) => {
  const {
    loginSubMenu,
    mobile,
    isUserLoggedIn,
    userPrefData,
    ...passThroughProps
  } = props;

  const ref = useRef();

  return (
    <Popover
      placement={mobile ? 'bottom' : 'bottom-end'}
      id={isUserLoggedIn ? 'loggedin-login-menu' : 'loggedout-login-menu'}
    >
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Box
              as={Button}
              variant="outlined"
              brandColor={isOpen ? 'neutral.dark' : 'white'}
              brandColorHover={'neutral.dark'}
              fontWeight="bold"
              minW={0}
              width={'unset'}
              fontSize={pxToRem(13)}
              px={4}
              label={'Log in'}
              _focus={{
                background: 'transparent',
                color: 'white',
                borderColor: 'white'
              }}
              {...passThroughProps}
              onClick={() => {
                useNavTracking({
                  event: !isOpen
                    ? MegaNavEvent.OpenMenu
                    : MegaNavEvent.CloseMenu,
                  navMenuName: 'Log in',
                  navMenuIndex: 0
                });
              }}
            >
              <Flex alignItems={'center'} justifyContent={'center'}>
                <Box
                  as={UserSVG}
                  width={'1rem'}
                  height={'1rem'}
                  marginRight={3}
                  display={isUserLoggedIn ? 'block' : mobile ? 'none' : 'block'}
                />

                {!isUserLoggedIn && 'Log in'}

                <AnimatedChevronDown
                  isOpen={isOpen}
                  width={pxToRem(10)}
                  height={pxToRem(6)}
                  marginLeft={3}
                />
              </Flex>
              <Box
                position={'absolute'}
                right={{ base: 'calc(25% - 0.25rem)', md: 4 }}
                bottom={'-0.8rem'}
                h={2}
                w={2}
                background={'#fff'}
                transform={'rotate(45deg)'}
                display={isOpen ? 'block' : 'none'}
              />
            </Box>
          </PopoverTrigger>

          <PopoverContent
            bg="white"
            border={0}
            padding={0}
            overflow="hidden"
            borderRadius={4}
            _focus={{ border: 0, outline: 'none' }}
            zIndex="dropdown"
            mx={{ base: '2rem', md: 0 }}
            width={{ base: 'calc(100% - 4rem)', md: '100%' }}
            shadow="md"
          >
            <PopoverBody padding={0} bg="white">
              {!isUserLoggedIn ? (
                <LoggedOutPopoverContent loginSubMenu={loginSubMenu} />
              ) : (
                <LoggedInPopoverContent user={userPrefData} />
              )}
            </PopoverBody>
            <Box ref={ref} />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default NavigationLoginButton;
