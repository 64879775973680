import { PageNavigationData } from '../../data/navigation.data';
import internalLink from '../../components/InternalLink';

export const getHrefFromPageNavigationData = (navItem: PageNavigationData) => {
  if (navItem.children.length > 0) {
    return null;
  }
  return navItem.link
    ? `${internalLink(navItem.link)}${navItem.internalLinkQueryString || ''}`
    : navItem.linkExternal || null;
};

export const isPageNavigationExternalLink = (navItem: PageNavigationData) => {
  if (!navItem) {
    return false;
  }
  try {
    return navItem.linkExternal && !!new URL(navItem.linkExternal);
  } catch (e) {
    return false;
  }
};

export const shouldOpenPageNavInNewTab = (navItem: PageNavigationData) => {
  return navItem.openInNewTab;
};
