import { useState } from 'react';
import Button from './Button';
import QuickLink from '../QuickLink';
import ModalVideoIframe from '../Modals/ModalVideoIframe';
import InternalLink from '../InternalLink';
import { ContentfulButtonLinkFragmentFragment } from '../../data/generated/graphql';

export interface ButtonLinkProps extends ContentfulButtonLinkFragmentFragment {
  isQuickLink?: boolean;
  style?: string;
}

const transformUrlforEnv = (externalUrl) => {
  const values = ['https://nzte.govt.nz', 'https://www.nzte.govt.nz'];
  const siteUrl = process.env.SITE_URL;
  const env = process.env.NODE_ENV;

  const ismyNzteUrl = values.some((el) => {
    return externalUrl.includes(el);
  });

  if (ismyNzteUrl && env !== 'production') {
    let domain = new URL(externalUrl);
    const newUrl = `${siteUrl}${domain.pathname}`;
    return newUrl;
  }

  return externalUrl;
};

const ButtonLink = (props: ButtonLinkProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const getLinkProps = () => {
    const { internalUrl, externalUrl, newTab, queryString, file } = props;

    let link = {
      href: null,
      target: null,
      rel: null,
      download: null
    };

    // Internal or external link
    if (internalUrl) {
      link.href = InternalLink(internalUrl);
    } else if (externalUrl) {
      link.href = transformUrlforEnv(externalUrl);
      link.rel = 'noopener';
    }

    if (link.href) {
      if (queryString) link.href += queryString;

      if (newTab) {
        link.target = '_blank';
        link.rel = 'noopener';
      }

      return link;
    }

    // Download file
    if (file && file.url) {
      link.href = file.url;
      link.target = '_blank';
      link.download = 'download';
      return link;
    }

    return null;
  };

  const ButtonLink = (componentProps: any) => {
    const {
      customColor,
      customActiveColor,
      customTextColor,
      customOutline
    } = props;

    if (props.isQuickLink) return <QuickLink {...componentProps} />;

    // Custom styles
    const customStyle =
      props.variant === 'custom'
        ? {
            defaultStyle: {
              color: customTextColor,
              backgroundColor: !customOutline ? customColor : null,
              borderColor: customColor
            },
            activeStyle: {
              color: customTextColor,
              backgroundColor: !customOutline ? customActiveColor : null,
              borderColor: customActiveColor
            }
          }
        : {};

    return <Button {...componentProps} {...customStyle} />;
  };

  const buttonLinkProps = {
    label: props.label,
    variant: props.variant,
    link: getLinkProps(),
    onClick: null,
    subtext: props?.subtext,
    style: props?.style
  };

  if (props.videoModal) {
    buttonLinkProps.link = null;
    buttonLinkProps.onClick = () => setShowModal(true);

    return (
      <>
        <ButtonLink {...buttonLinkProps} />
        <ModalVideoIframe
          show={showModal}
          video={props.videoModal}
          size="lg"
          onClose={() => setShowModal(false)}
        />
      </>
    );
  }

  return <ButtonLink {...buttonLinkProps} />;
};

export default ButtonLink;
