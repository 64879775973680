import React from 'react';
import { Box, BoxProps, Image } from '@chakra-ui/react';

type NzteLogoProps = {
  headerLogo?: string;
  headerLink?: string;
  logoHeight?: BoxProps['height'];
  logoWidth?: BoxProps['width'];
} & BoxProps;

const NzteLogo = (props: NzteLogoProps) => {
  const {
    headerLogo = '',
    headerLink = '',
    logoHeight = '60px',
    logoWidth = '160px',
    ...passThroughProps
  } = props;

  return (
    <Box
      as={'a'}
      href={headerLink ? headerLink : '/'}
      display={'block'}
      alignItems={'center'}
      textDecoration={'none'}
      {...passThroughProps}
    >
      <Image
        src={headerLogo ? headerLogo : '/logos/nzte-white.svg'}
        height={logoHeight}
        width={logoWidth}
        alt={'NZ Trade And Enterprise'}
      />
    </Box>
  );
};

export default NzteLogo;
