import React, { ReactNode } from 'react';
import Head from 'next/head';
import useSWR from 'swr';
import Footer from './Footer';
import { SectionContext } from './Section';
import { ContentfulSectionFragmentFragment } from '../data/generated/graphql';
import BackToTop from './BackToTop/BackToTop';
import NotificationBanner from './NotificationBanner';
import { SiteContext } from './SiteContext';
import { useServerSideRenderingDataContext } from './ServerSideRenderingDataContext';
import { UserData } from './Pages/PageSettings';
import Navigation from './Navigation/Navigation';
import { Box } from '@chakra-ui/react';
import { logWarn } from '../lib/appInsightsClient';
import { generateCanonicalUrl } from '../helpers/generateCanonicalTag';
import { useRouter } from 'next/router';

interface Props {
  section: ContentfulSectionFragmentFragment;
  children: ReactNode;
  title?: string;
  metaDescription?: string;
  ogImage?: string;
  className?: string;
  showMyAccount?: boolean;
  user?: UserData;
}

export const fetchNotification = (url) => fetch(url).then((res) => res.json());

export const getNotificationSwrOptions = () => {
  if (process.env.NEXT_PUBLIC_BUILD_NODE_ENV === 'development') {
    return {
      refreshInterval: 600 * 1000 // This needs to be more when running next in development mode or else page will refresh. check next.config.js pageBufferLength option.
    };
  }
  return {
    refreshInterval: 300 * 1000
  };
};

const Layout = (props: Props) => {
  const router = useRouter();
  const asPath = router.asPath;
  const dataContext = useServerSideRenderingDataContext();
  const { data } = dataContext.PAGE_LAYOUT;
  const { data: notificationData, error: notificationError } = useSWR(
    '/api/notification',
    fetchNotification,
    getNotificationSwrOptions()
  );

  const { ogImage, children, section } = props;

  const uiColorClass = section?.uiColor
    ? `page-ui-color-${section.uiColor}`
    : 'page-ui-color-black';

  if (!data || !data?.landingPageCollection?.items?.[0]) return null;
  if (notificationError) {
    // Most likely canceled by browser on page navigation (statuscode = 0)
    // Log as a "warn" because there is no impact to the user.
    logWarn(notificationError.message);
  }
  if (notificationData?.landingPageCollection?.items?.[0]?.notificationBanner)
    data.landingPageCollection.items[0].notificationBanner =
      notificationData.landingPageCollection?.items?.[0]?.notificationBanner;

  const site = data.landingPageCollection.items[0];

  const title = props.title ? props.title : site.title; // Default title

  const metaDescription = props.metaDescription
    ? props.metaDescription
    : site.metaDescription; // Default meta description

  const shareImage = site?.shareImage ? site.shareImage?.url : null; // Default share image
  const extraClassName = props.className ?? '';

  return (
    <SiteContext.Provider value={site}>
      <SectionContext.Provider value={props.section}>
        <Head>
          <title>{title}</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={metaDescription} />
          <meta
            property="og:url"
            content={generateCanonicalUrl({
              asPath
            })}
          />
          {ogImage ? (
            <meta property="og:image" content={ogImage} />
          ) : shareImage ? (
            <meta property="og:image" content={shareImage} />
          ) : null}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:text:title" content={title} />
        </Head>

        <Box id="app-wrap" className={`${uiColorClass} ${extraClassName}`}>
          <Navigation />

          <main className="main">
            {site?.notificationBanner &&
              section?.showNotificationBanner !== false && (
                <NotificationBanner
                  content={site.notificationBanner}
                  key="notification-banner"
                />
              )}
            {children}
            <Footer />
            <BackToTop />
          </main>
        </Box>
      </SectionContext.Provider>
    </SiteContext.Provider>
  );
};

export default Layout;
